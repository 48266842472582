import { color } from '@dreamworld/material-styles/color.js';
import { ThemeStyle } from '@dreamworld/material-styles/theme.js';
import { Shadow } from '@dreamworld/material-styles/shadow.js';
import { css, unsafeCSS } from 'lit';

export const theme = css`
  ${unsafeCSS(color)}
  ${unsafeCSS(ThemeStyle)}
    ${unsafeCSS(Shadow)}
    :host {
    /* START: Theme variables */
    --mdc-theme-background: #D8E3DF;
    --mdc-theme-text-secondary-on-background: rgba(0, 0, 0, 0.56);
    --mdc-theme-text-secondary-on-surface: rgba(0, 0, 0, 0.56);
    /* END: Theme vatiables */

    --divider-color: rgba(0, 0, 0, .10);

    --dw-icon-color-active-on-light: #c11e5c;
    --dw-icon-color-on-light: rgba(0, 0, 0, .54);
    --dw-icon-color-disabled-on-light: rgba(0, 0, 0, .38);

    --dw-icon-color: var(--dw-icon-color-on-light);
    --dw-icon-color-active: var(--dw-icon-color-active-on-light);
    --dw-icon-color-disabled: var(--dw-icon-color-disabled-on-light);
    --dw-icon-color-close-button: #b00020;
    --dw-icon-color-done-button: #259B24;
    --page-header-height: 56px;
    /* END: Theme variables */

    /* Checked icon color.*/
    --check-icon-color: #7CB342;


    /** START: Typography */
    --headline6-line-height: 1.5rem;

    --subtitle1-line-height: 24px;

    --body2-font-size: 0.875rem;
    --body2-line-height: 1.25rem;
    --body2-font-weight: 400;
    --body2-letter-spacing: 0.0178571429em;

    --button-line-height: 1.25rem;
    --subtitle1-line-height: 1.5rem;

    --color-green: #259B24;
    --color-orange: #FFA726;
    --color-grey: rgba(0,0,0, 0.38);
    --color-light-grey: rgba(0,0,0, 0.20);
    --tag-color: #F57D23;
    --smpv-shimmer-view-color: linear-gradient(to right, #3F3F3F, #4a4a4a4d, #3A3A3A);
    --info-background-color: rgba(0, 0, 0, 0.04);

    /** Region chip colors */
    --region-chip-background-color-village: rgba(0, 121, 107, 0.3);
    --region-chip-background-color-country: rgba(81, 45, 168, 0.3);
    --region-chip-background-color-sanghat: rgba(93, 64, 55, 0.3);
    --region-chip-background-color-district: rgba(104, 159, 56, 0.3)
  }
`;

export default theme;
