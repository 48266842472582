let initPromiseResolve;

/**
 * A Promise which is resolved when this module's total initialization is completed.
 */
let initPromise = new Promise((resolve) => {
  initPromiseResolve = resolve;
});

export const resolve = () => {
  initPromiseResolve();
}

/**
 * Return promise resolve when dreamdb initialization is completed.
 */
export default () => initPromise;