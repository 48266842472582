export const loadPage = async (pageName) => {
  try {
    switch (pageName) {
      case 'SPLASH_SCREEN':
        await import('./app/splash-screen.js');
        break;
      case 'NOT_FOUND':
        await import('./app/not-found.js');
        break;
      case 'UNDER_MAINTENANCE':
        await import('./app/under-maintenance.js');
        break;
      case 'PLAYERS':
        await import('./players/player-list-page.js');
        break;
      case 'VIDEOS':
        await import('./videos/video-list-page.js');
        break;
      case 'KENDRAS':
        await import('./kendras/kendra-list-page.js');
        break;
      case 'SCHEDULES':
        await import('./schedules/schedule-list-page.js');
        break;
      case 'SCHEDULE':
        await import('./schedules/view-page/schedule-view-page.js');
        break;
      case 'PERMISSION_DENIED':
        await import('./app/permission-denied.js');
        break;
      default:
        break;
    }
  } catch (err) {
    console.error(`Failed to import file for module ${pageName}`, err);
  }
};

export const loadDialog = async (dialogName) => {
  try {
    switch (dialogName) {
      case 'PLAYER':
        await import('./players/player-dialog.js');
        break;
      case 'KENDRA':
        await import('./kendras/view/kendra-view-dialog.js');
        break;
      case 'VIDEO':
        await import('./videos/video-view-dialog.js');
      default:
        break;
    }
  } catch (err) {
    console.error(`Failed to import dialog: ${dialogName}`, err);
  }
};

export const loadActionDialog = async (actionDialogName) => {
  try {
    switch (actionDialogName) {
      case 'PLAYER_STATUS_CONFIRM':
        await import('./players/player-status-confirm-dialog.js');
        break;
      case 'PLAYER_STATUS_REMOVE':
        await import('./players/player-remove-kendra-confirm-dialog.js');
        break;
      default:
        break;
    }
  } catch (err) {
    console.error(`Failed to import dialog: ${actionDialogName}`, err);
  }
};
