import { takeEvery, all } from 'redux-saga/effects';
import * as actions from './actions.js';
import store from '../store.js';

import * as snackbar from '@dreamworld/dw-snackbar/dw-snackbar.js';
import i18next from '../../localize/i18next.js';

function* onUpdateAvailalbe() {
  snackbar.show({
    message: i18next.t('newVersion.available'),
    timeout: 0,
    hideDismissBtn: true,
    actionButton: {
      caption: i18next.t('newVersion.buttons.update'),
      callback: () => {
        store.dispatch({ type: actions.UPGRADE });
      },
    },
  });
}

export default function* () {
  yield all([takeEvery(actions.UPGRADE_AVAILABLE, onUpdateAvailalbe)]);
}
