import { DwInput } from '@dreamworld/dw-input';
import { css } from '@dreamworld/pwa-helpers/lit.js';

export class SmpvInput extends DwInput {
  static get styles() {
    return [
      super.styles,
      css`
        :host {
          --dw-input-outlined-idle-border-color: var(--mdc-theme-divider-color);
        }

        :host([large]) .mdc-text-field__input {
          font-size: 20px;
        }

        :host([large]) .prefix-text {
          font-size: 20px;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.dense = true;
  }
}

window.customElements.define('smpv-input', SmpvInput);
