export const VIEW = 'KENDRAS_VIEW';

export const TOGGLE_SELECTION = 'KENDRAS_TOGGLE_SELECTION';
export const CLEAR_SELECTION = 'KENDRAS_CLEAR_SELECTION';

export const OPEN_FILTER = 'KENDRAS_OPEN_FILTER';
export const APPLY_FILTER = 'KENDRAS_APPLY_FILTER';
export const RESET_FILTER = 'KENDRAS_RESET_FILTER';

export const OPEN_IMPORT = 'KENDRAS_OPEN_IMPORT';
export const IMPORT = 'KENDRAS_IMPORT';
export const IMPORT_DONE = 'KENDRAS_IMPORT_DONE';
export const IMPORT_FAILED = 'KENDRAS_IMPORT_FAILED';

export const ADD = 'KENDRAS_ADD';
export const CREATE = 'KENDRAS_CREATE';
export const CREATE_DONE = 'KENDRAS_CREATE_DONE';
export const CREATE_FAILED = 'KENDRAS_CREATE_FAILED';

export const EDIT = 'KENDRAS_EDIT';
export const UPDATE = 'KENDRAS_UPDATE';
export const UPDATE_DONE = 'KENDRAS_UPDATE_DONE';
export const UPDATE_FAILED = 'KENDRAS_UPDATE_FAILED';

export const CONFIRM_HOLD = 'KENDRAS_CONFIRM_HOLD';
export const HOLD = 'KENDRAS_HOLD';
export const HOLD_DONE = 'KENDRAS_HOLD_DONE';
export const HOLD_FAILED = 'KENDRAS_HOLD_FAILED';

export const ACTIVATE = 'KENDRAS_ACTIVATE';
export const ACTIVATE_DONE = 'KENDRAS_ACTIVATE_DONE';
export const ACTIVATE_FAILED = 'KENDRAS_ACTIVATE_FAILED';

export const CONFIRM_DELETE = 'KENDRAS_CONFIRM_DELETE';
export const DELETE = 'KENDRAS_DELETE';
export const DELETE_DONE = 'KENDRAS_DELETE_DONE';
export const DELETE_FAILED = 'KENDRAS_DELETE_FAILED';

export const OPEN_ASSIGN_PLAYER = 'KENDRAS_OPEN_ASSIGN_PLAYER';

export const CONFIRM_REVOKE_PLAYER = 'KENDRAS_CONFIRM_REVOKE_PLAYER';


/**
 * Opens Kendra View Dialog.
 */
export const view = (kendraId) => {
  return {
    type: VIEW,
    kendraId
  };
};

export const toggleSelection = (kendraId) => {
  return {
    type: TOGGLE_SELECTION,
    kendraId
  };
}

export const clearSelection = () => {
  return {
    type: CLEAR_SELECTION
  };
}

/**
 * Opens Filter dialog.
 */
export const openFilter = () => {
  return {
    type: OPEN_FILTER
  };
};

/**
 * Updates list based on the filter criteria.
 * @param {Object} filters New filters applied by the user.
 */
export const applyFilter = (filters) => {
  return {
    type: APPLY_FILTER,
    filters,
  };
};

/**
 * Clears applied filters
 */
export const resetFilter = () => {
  return {
    type: RESET_FILTER
  };
};

export const openImport = () => {
  return {
    type: OPEN_IMPORT
  };
};

export const importKendras = (file) => {
  return {
    type: IMPORT,
    file
  }
};

export const _importDone = (kendras) => {
  return {
    type: IMPORT_DONE,
    kendras
  };
};

export const _importFailed = (error) => {
  return {
    type: IMPORT_FAILED,
    error
  }
};

export const add = () => {
  return {
    type: ADD
  };
};

export const create = (kendra) => {
  return {
    type: CREATE,
    kendra
  }
};

export const _createDone = (kendra) => {
  return {
    type: CREATE_DONE,
    kendra
  };
};

export const _createFailed = (kendra, error) => {
  return {
    type: CREATE_FAILED,
    kendra,
    error
  }
};

export const edit = (kendraId) => {
  return {
    type: EDIT,
    kendraId
  };
};

export const update = (kendra) => {
  return {
    type: UPDATE,
    kendra
  }
};

export const _updateDone = (kendra) => {
  return {
    type: UPDATE_DONE,
    kendra
  };
};

export const _updateFailed = (kendra, error) => {
  return {
    type: UPDATE_FAILED,
    kendra,
    error
  }
};

export const confirmHold = (kendraId) => {
  return {
    type: CONFIRM_HOLD,
    kendraId
  };
};

export const hold = (kendraId, reason) => {
  return {
    type: HOLD,
    kendraId,
    reason
  }
};

export const _holdDone = (kendraId) => {
  return {
    type: HOLD_DONE,
    kendraId
  };
};

export const _holdFailed = (kendraId, error) => {
  return {
    type: HOLD_FAILED,
    kendraId,
    error
  }
};

/**
 * Activates this Kendra, if it's status=ON_HOLD.
 * @param {String} kendraId 
 */
export const activate = (kendraId) => {
  return {
    type: ACTIVATE,
    kendraId
  }
};

export const _activateDone = (kendraId) => {
  return {
    type: ACTIVATE_DONE,
    kendraId
  };
};

export const _activateFailed = (kendraId, error) => {
  return {
    type: ACTIVATE_FAILED,
    kendraId,
    error
  }
};

export const confirmDelete = (kendraId) => {
  return {
    type: CONFIRM_DELETE,
    kendraId
  };
};

export const del = (kendraId) => {
  return {
    type: DELETE,
    kendraId
  }
};

export const _delDone = (kendraId) => {
  return {
    type: DELETE_DONE,
    kendraId
  };
};

export const _delFailed = (kendraId, error) => {
  return {
    type: DELETE_FAILED,
    kendraId,
    error
  }
};

export const confirmRevokePlayer = (kendraIds) => {
  return {
    type: CONFIRM_REVOKE_PLAYER,
    kendraIds
  };
};

export const openAssignPlayer = (kendraIds) => {
  return {
    type: OPEN_ASSIGN_PLAYER,
    kendraIds
  }
};
