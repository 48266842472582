import { html, css } from '@dreamworld/pwa-helpers/lit';
import { BaseElement } from '../base-element.js';
import { default as i18next } from '../../localize/i18next.js';
import { repeat } from 'lit/directives/repeat.js';

// Redux modules.
import { store } from '../../redux/store.js';
import * as app from '../../redux/app';
import * as router from '../../redux/router';

// Application logo

// Elements
import '../common/smpv-navigation-drawer.js';
import '../common/smpv-navigation-drawer-modal.js';
import '../common/smpv-list-item.js';
import { bhaktiChakra } from '../../icons/bhakti-chakra';


// Lodash
import get from 'lodash-es/get';
import * as scheduleSelectors from '../../redux/schedules/selectors.js';

export class AppDrawer extends BaseElement {
  static properties = {
    /**
     * `true` when drawer is opened.
     */
    _opened: { Boolean, state: true },

    /**
     * Navigation list items.
     */
    _items: { type: Array, state: true },

    /**
     * Current page id. Its computed from the router data.
     */
    _pageId: { type: String, state: true },

    /**
     * Schedule view type. e.g. `pending` or `past`
     */
    _scheduleViewType: { type: String }
  };

  static styles = [
    super.styles,
    css`
      :host {
        z-index: 3;
        background: var(--mdc-theme-background);
      }

      #bhakti-chakra {
        display: flex;
        justify-content: space-around;
        padding: 16px;
      }

      #bhakti-chakra svg {
        width: 100px;
        height: 136px;
      }

      smpv-list-item[selected] {
        --mdc-theme-text-primary: var(--mdc-theme-primary);
      }
    `,
  ];

  constructor() {
    super();
    this._setNavigationItems();
  }

  willUpdate(changedProps) {
    super.willUpdate(changedProps);
    // On layout change, if drawer is opened, close it.
    if (changedProps.has('layout') && this._opened) {
      store.dispatch(app.actions.updateDrawerOpened(false));
    }
  }

  render() {
    return html`
      ${this.layout === 'MOBILE' || this.layout === 'TABLET'
        ? html`
            <smpv-navigation-drawer-modal @navigation-drawer-changed=${this._onDrawerOpenedChanged} .opened=${this._opened}>
              ${this.contentTemplate}
            </smpv-navigation-drawer-modal>
          `
        : html` <smpv-navigation-drawer .opened=${true}> ${this.contentTemplate} </smpv-navigation-drawer> `}
    `;
  }

  get contentTemplate() {
    return html`
      <div id="bhakti-chakra">${bhaktiChakra}</div>
      ${repeat(
      this._items,
      (item) => item.id,
      (item) => {
        return html`
            <smpv-list-item
              id=${item.id}
              @click="${(e) => this._onItemSelectionChanged(e)}"
              .selected=${item.id === this._pageId || (item.subPages && item.subPages.includes(this._pageId) && get(item, 'params.type') === this._scheduleViewType)}
              .title1=${item.title}
              .leadingIcon=${item.leadingIcon}
              .hasLeadingIcon=${true}
              .leadingIconSymbol=${item.symbol}
            >
            </smpv-list-item>
          `;
      }
    )}
    `;
  }

  /**
   * Dispatches redux action to update `drawerOpened` status in redux state.
   * @param {Object} e Event of drawer.
   */
  _onDrawerOpenedChanged(e) {
    const opened = get(e, 'detail.opened');
    if (this._opened === opened) {
      return;
    }
    store.dispatch(app.actions.updateDrawerOpened(opened));
  }

  /**
   * When item is selected, navigates to it's associated page.
   * @param {Object} e `selection-changed` event details.
   */
  _onItemSelectionChanged(e) {
    const el = e.target;
    router.dwRouter.navigatePage(this._findPageName(el.id), this._findPageParams(el.id));

    if (this.layout === 'MOBILE' || this.layout === 'TABLET') {
      store.dispatch(app.actions.updateDrawerOpened(false));
    }

    // Remove dark overlay after clicked on item
    el.blur();
  }

  _findPageName(id) {
    const pageNameMap = {
      players: 'PLAYERS',
      videos: 'VIDEOS',
      kendras: 'KENDRAS',
      schedules_pending: 'SCHEDULES',
      schedules_past: 'SCHEDULES',
    }
    return pageNameMap[id]
  }

  _findPageParams(id) {
    const item = this._items.filter(item => item.id === id)[0];
    return item && item.params;
  }

  _setNavigationItems() {
    this._items = [
      { id: "players", name: 'PLAYERS', title: i18next.t('drawerItems.players'), leadingIcon: 'ondemand_video' },
      { id: "videos", name: 'VIDEOS', title: i18next.t('drawerItems.videos'), leadingIcon: 'video_library' },
      { id: "kendras", name: 'KENDRAS', title: i18next.t('drawerItems.kendras'), leadingIcon: 'event' },
      { id: "schedules_pending", name: 'SCHEDULES', title: i18next.t('drawerItems.schedulesPending'), leadingIcon: 'date_range', params: { type: 'pending' }, subPages: ['schedule'] },
      { id: "schedules_past", name: 'SCHEDULES', title: i18next.t('drawerItems.schedulesPast'), leadingIcon: 'date_range', params: { type: 'past' }, subPages: ['schedule'] },
    ];
  }

  /**
   * @override
   */
  _setLanguage(newLanguage) {
    super._setLanguage && super._setLanguage(newLanguage);
    this._setNavigationItems();
  }

  stateChanged(state) {
    super.stateChanged && super.stateChanged(state);
    this._opened = app.selectors.drawerOpened(state);
    const pageName = router.selectors.pageName(state);
    const pageParams = router.selectors.pageParams(state);
    this._scheduleViewType = scheduleSelectors.viewType(state);
    if (pageParams && pageParams.type) {
      this._pageId = `${pageName.toLowerCase()}_${pageParams.type}`
    } else {
      this._pageId = pageName.toLowerCase()
    }
  }
}
customElements.define('app-drawer', AppDrawer);
