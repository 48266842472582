import { LitElement, html, css } from '@dreamworld/pwa-helpers/lit.js';
import { default as i18next, init as i18nextInit } from '../../localize/i18next.js';
import typography from '../../theme/typography.js';
import sharedStyles from '../../theme/shared-styles.js';
import { bhaktiChakra } from '../../icons/bhakti-chakra.js';

/**
 * Shows app splash screen when state of the app is not known.
 *
 * Usage pattenr:
 *  - <splash-screen></splash-screen>
 */
export class SplashScreen extends LitElement {
  static styles = [
    typography,
    sharedStyles,
    css`
      :host {
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items:center;
      }

      svg {
        width: 300px;
        height: 400px;
      }
    `
  ];

  render() {
    return html`
      ${bhaktiChakra}
    `;
  }
}
customElements.define('splash-screen', SplashScreen);
