import { LitElement, html, css } from '@dreamworld/pwa-helpers/lit.js';
import { default as i18next, init as i18nextInit } from '../../localize/i18next.js';

// Redux
import { store } from '../../redux/store';
import { connect } from 'pwa-helpers/connect-mixin.js';
import * as authActions from '../../redux/auth/actions.js';
import * as authSelectors from '../../redux/auth/selectors.js';

// Styles
import typography from '../../theme/typography.js';
import sharedStyles from '../../theme/shared-styles.js';
import tailwindcss from '../../tailwind.css';

// Custom components
import { loginLogo } from '../../icons/login.js';
import '@dreamworld/dw-surface';
import '../../components/common/smpv-input.js';
import '../../components/common/smpv-button.js';
import '../../components/common/smpv-loader.js';

/**
 * Shows Login Page.
 *
 * Usage pattenr:
 *  - <login-page></login-page>
 */
export class LoginPage extends connect(store)(LitElement) {
  static styles = [
    typography,
    sharedStyles,
    tailwindcss,
    css`
      :host {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: var(--mdc-theme-primary);
      }

      .divider {
        border-color: var(--mdc-theme-divider-color);
      }
    `,
  ];

  static properties = {
    /**
     * User's username
     * Use to disabled login button and pass as a argument in login action
     */
    _userName: { type: String },

    /**
     * User's password
     * Use to disabled login button and pass as a argument in login action
     */
    _password: { type: String },

    /**
     * When it's true, shown loader insted of login action button
     */
    _isLoginInprogress: { type: Boolean, reflect: true },
  };

  render() {
    return html`
      <dw-surface elevation="2" bg="surface" class="rounded-3xl flex">
        <div class="p-6 border-r border-solid divider">${loginLogo}</div>
        <div class="flex flex-col items-center justify-center p-6">
          <div class="headline5 mb-9">Login</div>
          <smpv-input
            class="mb-6 w-96"
            ?disabled=${this._isLoginInprogress}
            label="Username"
            required
            @value-changed="${this._onUserNameChange}"
          ></smpv-input>
          <smpv-input
            class="mb-9 w-96"
            ?disabled=${this._isLoginInprogress}
            label="Password"
            type="password"
            required
            @enter=${this._onPasswordEnter}
            @value-changed="${this._onPasswordChange}"
          ></smpv-input>
          ${this._actionTemplate}
        </div>
      </dw-surface>
    `;
  }

  get _actionTemplate() {
    if (this._isLoginInprogress) {
      return html` <smpv-loader class="self-center w-9 h-9" indeterminate></smpv-loader> `;
    }

    return html`
      <smpv-button type="primary" label="Login" ?disabled="${!this._userName || !this._password}" @click="${this._logIn}"></smpv-button>
    `;
  }

  _onUserNameChange(e) {
    this._userName = e.detail.value;
  }

  _onPasswordChange(e) {
    this._password = e.detail.value;
  }

  _onPasswordEnter() {
    if (!this._userName || !this._password) {
      return;
    }

    this._logIn();
  }

  _logIn() {
    store.dispatch(authActions.login(this._userName, this._password));
  }

  stateChanged(state) {
    this._isLoginInprogress = authSelectors.loginRequestInProgress(state);
  }
}
customElements.define('login-page', LoginPage);
