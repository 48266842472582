import { filter, forEach, get, isEmpty, pick, size, take, orderBy, groupBy, map } from 'lodash-es';
import memoize from 'proxy-memoize';

import { doc as dreamdbDoc, queryResult } from '../dreamdb/selectors.js';
import { kendra } from '../kendras/selectors.js';
import * as router from '../router/selectors.js';
import { LIST_PAGE_NAME, PLAYER_STATUS_ORDERS } from './constants.js'

export const allPlayers = (state) => queryResult(state, 'all-players');

export const allActive = memoize((state) =>  {
  const _players = allPlayers(state);
  return filter(_players, {status: 'ACTIVE'});
});

export const findByCode = memoize(({state, playerCode}) => {
  const player = allPlayers(state).filter((p) => {
    return p.code == playerCode;
  })[0];
  return player;
});

const districtMatchesRegion = (district, region) => {
  if (!district) {
    return false;
  }
  return district.indexOf(region) === 0;
};

const getSortTime = (doc) => {
  let time = 0;
  switch (doc.status) {
    case 'REGISTERED':
      time = doc.createdAt;
      break;
    case 'ACTIVE':
    case 'RESET':
      time = doc.registrationApprovedAt;
      break;
    case 'ARCHIVE':
    case 'DESTROY':
      time = doc.archivedAt;
  }
  return time;
};

export const listLimit = (state) => state.players.listLimit;

/**
 * Returns number of Players which matches the filtered criteria.
 * This count isn't affected due to pagination (which page you are looking at it now).
 * It can be used to identify whether end-of-the-list is reached?
 */
export const filteredPlayersCount = (state) => {
  return filteredPlayers(state).length;
};

export const filteredPlayers = memoize((state) => {
  const _allPlayers = allPlayers(state);
  if (!_allPlayers) {
    return [];
  }

  const _filters = filters(state);
  if (!_filters) {
    return _allPlayers;
  }

  return filter(_allPlayers, (player) => {
    const statusMatched = _filters.status ? player.status === _filters.status : true;
    const codeMatched = _filters.code ? player.code == _filters.code : true; //Note: playerCode is parsed as Number.
    const onlineStatusMatched = _filters.onlineStatus ? player.onlineStatus === _filters.onlineStatus : true;
    const regionMatched = _filters.region ? districtMatchesRegion(player.region, _filters.region) : true;
    const noOfKendrasMatched = _filters.noOfKendras ? size(player.kendras) === _filters.noOfKendras : true;
    return statusMatched && codeMatched && onlineStatusMatched && regionMatched && noOfKendrasMatched;
  });
});

export const listGroupedByStatus = memoize((state) => {
  let _filteredPlayers = filteredPlayers(state);
  const limit = listLimit(state);

  _filteredPlayers = orderBy(_filteredPlayers, [(player) => PLAYER_STATUS_ORDERS[player.status], getSortTime], ['asc', 'desc']);
  _filteredPlayers = take(_filteredPlayers, limit);

  const groups = [];
  let lastStatus;
  _filteredPlayers.forEach((player) => {
    let group;
    if (player.status == lastStatus) {
      group = groups[groups.length - 1];
    } else {
      group = { status: player.status, players: [] };
      groups.push(group);
      lastStatus = player.status;
    }
    group.players.push(player);
  });
  return groups;
});

export const player = (state, playerId) => dreamdbDoc(state, playerId);

export const currentPlayer = (state) => {
  const playerId = router.dialogParams(state)?.playerId;
  if (!playerId) {
    return;
  }
  return player(state, playerId);
};

/**
 * Returns Kendras using this Player. Kendras are sorted by their week day & time.
 */
export const playerKendras = memoize(({ state, playerId }) => {
  const p = player(state, playerId);
  let kendras = [];
  forEach(p?.kendras, (kendraId) => {
    const k = kendra(state, kendraId);
    k && kendras.push(k);
  });
  kendras = orderBy(kendras, (k) => [k.weekDay, k.time]);
  return kendras;
});

const req = (state, playerId, action) => get(state, ['players', 'reqs', action, playerId]);

export const approveRejectInProgress = (state, playerId) => {
  const approveReq = req(state, playerId, 'approve');
  const rejectReq = req(state, playerId, 'approve');
  return approveReq?.status === 'IN_PROGRESS' || rejectReq?.status === 'IN_PROGRESS';
};

/** Router based selectors - START */
export const filters = (state) => {
  const pageName = router.pageName(state);
  if (pageName !== LIST_PAGE_NAME) {
    return;
  }

  const pageParams = router.pageParams(state);
  const filters = pick(pageParams, ['status', 'code', 'region', 'noOfKendras', 'onlineStatus']);
  return isEmpty(filters) ? undefined : filters;
};

export const filterDialogOpened = (state) => {
  const pageName = router.pageName(state);
  if (pageName !== LIST_PAGE_NAME) {
    return false;
  }

  const pageParams = router.pageParams(state);
  return pageParams?.action === 'filter';
};

export const playerAction = (state) => {
  const pageName = router.pageName(state);
  if (pageName !== LIST_PAGE_NAME) {
    return;
  }

  const pageParams = router.pageParams(state);
  const action = pageParams?.action;
  if (['approve', 'reject'].indexOf(action) !== -1) {
    return pick(pageParams, ['playerId', 'action']);
  }
};
/** Router based selectors - END */
