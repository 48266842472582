import { select, take, takeEvery, call, put, all, fork } from 'redux-saga/effects';
import dreamdb, { selectors as dreamdbSelectors } from '@dw/dreamdb-client';
import * as app from '../app';
import * as authSelectors from '../auth/selectors.js';
import * as authActions from '../auth/actions.js';

import { resolve as initPromiseResolve } from './wait-for-init.js';

let couchdb;

/**
 * init dreamdb
 */
function* initDreamDb() {
  try {
    let state = yield select();
    let config = app.selectors.config(state);
    couchdb = dreamdb.connect(config.dreamDbUrl);
    
  } catch (error) {
    console.error("Error while initializing dreamdb::", error);
  }
}

/**
 * It performs dreamdb authentication.
 */
function* performAuth() {
  try {
    const state = yield select();
    const authToken = authSelectors.dreamdbAuthToken(state);
    if (!authToken) {
      return false;
    }
    couchdb.auth(authToken);
    initPromiseResolve();
    console.info('dreamdb: performAuth: done');
    return true;
  } catch (error) {
    console.error("Error while authenticating dreamdb::", error);
    return false;
  }
}

function* init() {
  //TODO: Wait till authentication is done.
  yield call(initDreamDb);
  // yield call(performAuth);
}

/**
 * It called on `AUTH_LOGOUT` action.
 * It clears local databases.
 */
function* logout() {
  try {
    yield dreamdb.couchdb().dispose();
    window.setTimeout(() => window.location.reload());
  } catch (e) {
    console.error("logout: failed. error=", e);
  }
}

export default function* () {
  yield call(init);
  const authDone = yield call(performAuth);
  if (!authDone) {
    yield take(authActions.LOGIN_DONE);
    yield call(performAuth);
    yield take(authActions.LOGOUT);
    yield call(logout);
  }
}
