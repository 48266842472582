import { typography as DWTypography } from '@dreamworld/material-styles/typography.js';
import { css, unsafeCSS } from 'lit';

export const typography = css`
    ${unsafeCSS(DWTypography)}

    h1, h2, h3, h4, h5, h6 {
      margin: 0;
    }
`;

export default typography;