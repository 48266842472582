export const LIST_PAGE_OPENED = 'SCHEDULES_LIST_PAGE_OPENED';
export const LIST_PAGE_CLOSED = 'SCHEDULES_LIST_PAGE_CLOSED';

export const LOAD_NEXT_PAGE = 'SCHEDULES_LOAD_NEXT_PAGE';
export const UPDATE_LIST_LIMIT = 'SCHEDULES_UPDATE_LIST_LIMIT';
export const UPDATE_LIST_ITEMS = 'SCHEDULES_UPDATE_LIST_ITEMS';
export const LIST_SNAPSHOT_CHANGED = 'SCHEDULES_LIST_SNAPSHOT_CHANGED';


export const OPEN_FILTER = 'SCHEDULES_OPEN_FILTER';
export const APPLY_FILTER = 'SCHEDULES_APPLY_FILTER';
export const RESET_FILTER = 'SCHEDULES_RESET_FILTER';

export const TOGGLE_SELECTION = 'SCHEDULES_TOGGLE_SELECTION';
export const CLEAR_SELECTION = 'SCHEDULES_CLEAR_SELECTION';
export const CONFIRM_DELETE = 'SCHEDULES_CONFIRM_DELETE';
export const DELETE = 'SCHEDULES_DELETE';

export const OPEN_ADD_NEW = 'SCHEDULES_OPEN_ADD_NEW';
export const VIEW_DETAIL = 'SCHEDULES_VIEW_DETAIL';
export const CREATE = 'SCHEDULES_CREATE';
export const CREATE_SEQUENCE = 'SCHEDULES_CREATE_SEQUENCE';

export const VIEW_SCHEDULE = 'SCHEDULES_VIEW_SCHEDULE';
export const VIEW_PAGE_OPENED = 'SCHEDULES_VIEW_PAGE_OPENED';
export const VIEW_PAGE_CLOSED = 'SCHEDULES_VIEW_PAGE_CLOSED';
/**
 * In contrast to VIEW_PLAYER, it's always dispatched even when accessed through direct-url.
 */
export const VIEW_PLAYER_DIALOG_OPENED = 'SCHEDULES_VIEW_PLAYER_DIALOG_OPENED';
export const VIEW_PLAYER_DIALOG_CLOSED = 'SCHEDULES_VIEW_PLAYER_DIALOG_CLOSED';
export const APPLY_VDS_FILTER = 'SCHEDULES_APPLY_VDS_FILTER';
export const RESET_VDS_FILTER = 'SCHEDULES_RESET_VDS_FILTER';
export const APPLY_VPS_FILTER = 'SCHEDULES_APPLY_VPS_FILTER';
export const RESET_VPS_FILTER = 'SCHEDULES_RESET_VPS_FILTER';
export const VIEW_PLAYER = 'SCHEDULES_VIEW_PLAYER';
export const VIEW_KENDRAS = 'SCHEDULES_VIEW_KENDRAS';

export const _listPageOpened = () => {
  return {
    type: LIST_PAGE_OPENED,
  };
};

export const _listPageClosed = () => {
  return {
    type: LIST_PAGE_CLOSED,
  };
};

export const loadNextPage = () => {
  return {
    type: LOAD_NEXT_PAGE,
  };
};


export const _updateListLimit = (limit) => {
  return {
    type: UPDATE_LIST_LIMIT,
    limit
  }
}

export const _updateListItems = (items, eol) => {
  return {
    type: UPDATE_LIST_ITEMS,
    items,
    eol
  }
}

/**
 * Dispatched when snapshot of a dreamdb query (for list page) is changed.
 */
export const _listSnapshotChanged = (docs) => {
  return {
    type: LIST_SNAPSHOT_CHANGED,
    docs
  }
}

export const viewSchedule = (scheduleId) => {
  return {
    type: VIEW_SCHEDULE,
    scheduleId
  };
}

export const openFilter = () => {
  return {
    type: OPEN_FILTER
  }
};

export const applyFilter = (filters) => {
  return {
    type: APPLY_FILTER,
    filters
  };
}

export const resetFilter = () => {
  return {
    type: RESET_FILTER
  };
}

export const toggleSelection = (scheduleId) => {
  return {
    type: TOGGLE_SELECTION,
    scheduleId
  };
}

export const clearSelection = () => {
  return {
    type: CLEAR_SELECTION
  };
}

export const confirmDelete = (scheduleIds) => {
  return {
    type: CONFIRM_DELETE,
    scheduleIds
  };
}

export const del = (scheduleIds) => {
  return {
    type: DELETE,
    scheduleIds
  };
}

export const openAddNew = () => {
  return {
    type: OPEN_ADD_NEW
  };
}

/**
 * 
 * @param {String} view Possible values: 'kendras', 'videos'.
 * @returns 
 */
export const viewDetail = (view) => {
  return {
    type: VIEW_DETAIL,
    view
  };
}

export const createSequence = (request) => {
  return {
    type: CREATE_SEQUENCE,
    request
  };
}

export const create = (request) => {
  return {
    type: CREATE,
    request
  };
}


export const _viewPageOpened = () => {
  return {
    type: VIEW_PAGE_OPENED,
  };
};

export const _viewPageClosed = () => {
  return {
    type: VIEW_PAGE_CLOSED,
  };
};


export const _viewPlayerDialogOpened = (playerId) => {
  return {
    type: VIEW_PLAYER_DIALOG_OPENED,
    playerId
  };
};

export const _viewPlayerDialogClosed = (playerId) => {
  return {
    type: VIEW_PLAYER_DIALOG_CLOSED,
    playerId
  };
};

export const applyVdsFilter = (filters) => {
  return {
    type: APPLY_VDS_FILTER,
    filters
  };
};

export const resetVdsFilter = () => {
  return {
    type: RESET_VDS_FILTER
  };
};

export const applyVpsFilter = (filters) => {
  return {
    type: APPLY_VPS_FILTER,
    filters
  };
};

export const resetVpsFilter = () => {
  return {
    type: RESET_VPS_FILTER
  };
};

export const viewPlayer = (playerId) => {
  return {
    type: VIEW_PLAYER,
    playerId
  };
};

export const viewKendras = () => {
  return {
    type: VIEW_KENDRAS
  };
};