export const LIST_PAGE_NAME = "SCHEDULES";
export const VIEW_PAGE_NAME = "SCHEDULE";
export const PAGE_SIZE = 25;


export const VDS_STATUS_ORDERS = {
  PENDING: 0,
  IN_PROGRESS: 1,
  COMPLETED: 2,
  ERROR: 3,
  // VALIDATING: 4,
  // UNKNOWN: 5,
};

export const VPS_STATUS_ORDERS = {
  SKIPPED: 0,
  PENDING: 1,
  PLAYING: 2,
  PAUSED: 3,
  PLAYED: 4,
};

export const VDS_STATUS_GROUP_MAP = {
  UNKNOWN: "PENDING",
  PENDING: "PENDING",
  IN_PROGRESS: "IN_PROGRESS",
  VALIDATING: "IN_PROGRESS",
  ERROR: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
};