import get from 'lodash-es/get';
import moment from "moment/dist/moment.js";
import memoize from 'proxy-memoize';

/**
 * @returns {String} layout based on the device. Possible values: 'MOBILE', 'TABLET' or 'DESKTOP'
 */
export const layout = memoize((state) => {
  const _layout = get(state, 'device-info.layout');
  if (_layout === 'small') {
    return 'MOBILE';
  }

  if (_layout === 'medium' || _layout === 'large') {
    return 'TABLET';
  }
  return 'DESKTOP';
});

/**
 * @returns {Boolean} `true` when drawer is opened.
 */
export const drawerOpened = memoize((state) => {
  const _layout = layout(state);
  if (_layout === 'MOBILE' || _layout === 'TABLET') {
    return get(state, `app.drawerOpened`);
  }

  return true;
});

/**
 * @returns {Number} Number of records per page. Its based on the current layout.
 */
export const recordsPerPage = memoize((state) => {
  const _layout = layout(state);
  if (_layout === 'DESKTOP') {
    return 50;
  }

  if (_layout === 'TABLET') {
    return 50;
  }

  return 50;
});


export const config = (state) => get(state, `app.config`);

export const absDateTime = (timestamp) => moment(timestamp).format('DD MMM, yyyy hh:mm A')

export const weekDay = (timestamp) => moment(timestamp).format('dddd')