import { css } from 'lit';
import { MdNavigationDrawerModal } from '@material/web/navigationdrawer/navigation-drawer-modal';

export class SmpvNavigationDrawerModal extends MdNavigationDrawerModal {
  static styles = [
    super.styles,
    css`
      :host {
        --md-navigation-drawer-modal-container-width: 260px;
        --md-navigation-drawer-modal-scrim-opacity: 0.32;

        // Border radius
        --md-navigation-drawer-modal-container-shape-start-start: 0;
        --md-navigation-drawer-modal-container-shape-end-start: 0;
        --md-navigation-drawer-modal-container-shape-end-end:0;
        --md-navigation-drawer-modal-container-shape-start-end:0;
      }

      .md3-navigation-drawer-modal--scrim-visible {
        background-color: var(--app-drawer-scrim-background-color, #000);
      }
    `
  ];
}
customElements.define('smpv-navigation-drawer-modal', SmpvNavigationDrawerModal);
