import { filter, forEach, get, isEmpty, pick, size, take, orderBy, groupBy, map, sortBy, takeRight, split, isArray, last } from 'lodash-es';
import memoize from 'proxy-memoize';
import moment from "moment/dist/moment.js";
import { doc, doc as dreamdbDoc, queryResult } from '../dreamdb/selectors.js';
import { kendra, kendraMatchesQuery, kendraRegion } from '../kendras/selectors.js';
import * as router from '../router/selectors.js';
import { LIST_PAGE_NAME, VIEW_PAGE_NAME, VDS_STATUS_GROUP_MAP } from './constants';
import { player } from '../players/selectors.js';
import { findBetween as findVideosBetween, findFrom as findVideosFrom, video } from '../videos/selectors.js';
import { createSelector } from 'reselect';

export const listLimit = (state) => state.schedules.list.limit;

/** Router based selectors - START */
export const filters = memoize((state) => {
  const pageName = router.pageName(state);
  if (pageName !== LIST_PAGE_NAME) {
    return;
  }

  const pageParams = router.pageParams(state);
  const filters = pick(pageParams, ['weekFrom', 'weekTo', 'kendraCriteria']);
  if (typeof filters.kendraCriteria === 'string') {
    filters.kendraCriteria = [filters.kendraCriteria];
  }
  return isEmpty(filters) ? undefined : filters;
});

export const listType = (state) => {
  const pageName = router.pageName(state);
  if (pageName !== LIST_PAGE_NAME) {
    return;
  }

  const pageParams = router.pageParams(state);
  return pageParams.type;
}

/**
 * @param {Object} state
 * @returns {String} type of the schedule. Possible values: 'past' or 'pending'
 */
export const viewType = (state) => {
  const pageName = router.pageName(state);
  if (pageName !== VIEW_PAGE_NAME) {
    return;
  }

  const scheduleId = curScheduleId(state);
  const doc = dreamdbDoc(state, scheduleId);

  if(!doc){
    return;
  }

  return isPastWeek(doc.week) ? 'past' : 'pending';
}

export const filterDialogOpened = (state) => {
  const pageName = router.pageName(state);
  if (pageName !== LIST_PAGE_NAME) {
    return false;
  }

  const pageParams = router.pageParams(state);
  return pageParams?.action === 'filter';
};

export const curScheduleId = (state) => {
  const pageName = router.pageName(state);
  if (pageName !== VIEW_PAGE_NAME) {
    return false;
  }

  const pageParams = router.pageParams(state);
  return pageParams.scheduleId;
}

/**
 * page parameter `schedules` may be String or Array.
 * This selector ensures that it always returns an Array.
 */
export const pageParamsSchedules = memoize((state) => {
  const schedules = router.pageParams(state).schedules;
  if (!schedules) {
    return schedules;
  }

  return isArray(schedules) ? schedules : [schedules];
});

/** Router based selectors - END */

export const eol = (state) => state.schedules.list.eol;

export const list = (state) => state.schedules.list.items;

export const selection = (state) => state.schedules.list.selection;

export const downloadStatus = (state, scheduleId, playerId) => dreamdbDoc(state, `sds_${scheduleId}_${playerId}`);

export const playStatus = (state, scheduleId, kendraId) => dreamdbDoc(state, `sps_${scheduleId}_${kendraId}`);

export const downloadSummary = (state, scheduleId) => dreamdbDoc(state, scheduleId.replace('sd_', 'sdsa_'));

export const playSummary = (state, scheduleId) => dreamdbDoc(state, scheduleId.replace('sd_', 'spsa_'));


export const isPastWeek = (week) => {
  return week < curWeek();
}

/**
 * Returns the date corresponding to the Monday of the week, in which given `date` falls in.
 * @param {*} date
 * @returns
 */
export const week = (date) => {
  const _moment = typeof date === 'string' ? moment(date) : date;
  return _moment.startOf('isoWeek').format('yyyy-MM-DD');
}

export const curWeek = () => {
  return moment().startOf('isoWeek').format('yyyy-MM-DD');
}

export const nextWeek = (date) => {
  const _week = date ? week(date) : curWeek();
  return moment(_week).add(1, 'week').format('yyyy-MM-DD');
}

export const prevWeek = (date) => {
  const _week = date ? week(date) : curWeek();
  return moment(_week).startOf('isoWeek').subtract(7, 'days').format('yyyy-MM-DD');
}

export const computeWeeksBetween = (startWeek, endWeek) => {
  const weeks = [];
  const start = moment(startWeek);
  const end = moment(endWeek);
  const count = end.diff(start, 'weeks');
  return computeWeeksFrom(startWeek, count + 1);
}

export const computeWeeksFrom = (startWeek, count) => {
  const weeks = [];
  let _week = moment(week(startWeek));
  while (count > 0) {
    weeks.push(_week.format('yyyy-MM-DD'));
    _week = _week.add(1, 'week');
    count--;
  }
  return weeks;
}

export const computeEndWeek = memoize(({ state, startWeek, startVideo, endVideo }) => {
  const videos = findVideosBetween(state, startVideo, endVideo);
  const weeks = computeWeeksFrom(startWeek, videos.length);
  return last(weeks);
});

export const computeEndVideo = memoize(({ state, startWeek, startVideo, endWeek }) => {
  const weeks = computeWeeksBetween(startWeek, endWeek);
  const videos = findVideosFrom(state, startVideo, weeks.length);
  return last(videos);
});

export const schedule = (state, scheduleId) => doc(state, scheduleId);

export const curSchedule = (state) => {
  const pageName = router.pageName(state);
  if (pageName !== VIEW_PAGE_NAME) {
    return;
  }

  const pageParams = router.pageParams(state);
  return schedule(state, pageParams.scheduleId);
}

export const vdsFilters = (state) => {
  const pageName = router.pageName(state);
  if (pageName !== VIEW_PAGE_NAME) {
    return;
  }

  const pageParams = router.pageParams(state);
  const filters = pick(pageParams, ['status', 'region', 'playerCode']);
  return isEmpty(filters) ? undefined : filters;
}

export const vpsFilters = (state) => {
  const pageName = router.pageName(state);
  if (pageName !== VIEW_PAGE_NAME) {
    return;
  }

  const pageParams = router.pageParams(state);
  const filters = pick(pageParams, ['status', 'region', 'kendraCode', 'playTime']);
  return isEmpty(filters) ? undefined : filters;
};


export const vdsGroupedByStatus = memoize((state) => {
  const scheduleId = curScheduleId(state);
  let vdss = queryResult(state, `vdss-by-${scheduleId}`);
  const _filters = vdsFilters(state);
  if (_filters) {
    vdss = filter(vdss, ((vds) => {
      const statusMatched = (
        !_filters.status ||
        vds.status === _filters.status ||
        VDS_STATUS_GROUP_MAP[vds.status] === _filters.status
      );
      const _player = player(state, vds.playerId);
      const playerCodeMatched = (!_filters.playerCode || _filters.playerCode == _player.code);
      const regionMatched = playerKendrasMatchesAnyRegion(state, _player, _filters.region);
      return statusMatched && playerCodeMatched && regionMatched;
    }));
  }

  //sort by last updated at
  vdss = orderBy(vdss, (vds) => [vds.lastUpdatedAt]);

  let groups = groupBy(vdss, function (vds) {
    const status = VDS_STATUS_GROUP_MAP[vds.status]
    return status;
  })
  groups = map(groups, function (records, status) {
    return {
      status: status,
      vds: records
    }
  })
  return groups;

});

const playerKendrasMatchesAnyRegion = (state, player, regions) => {
  if (!regions || regions.length == 0) {
    return true;
  }

  const _kendraIds = player.kendras;

  for (let i = 0; i < _kendraIds.length; i++) {
    const _kendraId = _kendraIds[i];
    const _kendra = kendra(state, _kendraId);
    if (kendraMatchesAnyRegion(_kendra, regions)) {
      return true;
    }
  }
  return false;
}

const kendraMatchesAnyRegion = (kendra, regions) => {
  if (!regions || regions.length == 0) {
    return true;
  }

  const kregion = kendraRegion(kendra);
  for (let i = 0; i < regions.length; i++) {
    const region = regions[i];
    if (kendraMatchesQuery(kregion, region)) {
      // console.log('kendraMatchesQuery', kregion, region);
      return true;
    }
  }
  return false;
}

const allVpss = (state) => {
  const scheduleId = curScheduleId(state);
  return queryResult(state, `vpss-by-${scheduleId}`);
}

/**
 * Returns VideoPlayStatus[] for the current schedule (and considering filters), sorted by their play time.
 */
export const curVpss = memoize((state) => {
  const vpss = allVpss(state);
  const filters = vpsFilters(state);

  let _list = [];
  forEach(vpss, ((vps) => {
    const _kendra = kendra(state, vps.kendraId);

    if(!isEmpty(_kendra)){
      const _player = player(state, _kendra.player);
      const statusMatched = !filters || (!filters.status || vps.status === filters.status);
      const kendraCodeMatched = !filters || (!filters.kendraCode || filters.kendraCode == _kendra.code);
      const regionMatched = !filters || kendraMatchesAnyRegion(_kendra, filters.region);
      const playTimeMatched = !filters || !filters.playTime || (moment(vps.time).format('YYYY-MM-DD') === filters.playTime);

      if(statusMatched && kendraCodeMatched && regionMatched && playTimeMatched){
        _list.push({...vps, kendra: _kendra, player: _player });
      }
    }
  }));

  return sortBy(_list, 'time');
});

export const playerVideoPlayStatuses = memoize((state) => {
  const scheduleId = curScheduleId(state);
  if (!scheduleId) {
    console.error('playerVideoPlayStatusses: Ensure you are on schedule-view page');
    return;
  }
  const pageParams = router.pageParams(state);
  const playerId = pageParams.playerId;

  if (!playerId) {
    console.error('playerVideoPlayStatusses: Ensure you are on schedule-player-view-dialog');
    return;
  }

  const vpss = queryResult(state, `vpss-by-${scheduleId}-${playerId}`);
  return sortBy(vpss, vps => vps.time);
});


function tokenize(region) {
  return split(region, '/').map((token) => token.trim());
}


export const scheduleKendras = memoize(({ state, scheduleId }) => {
  const _schedule = schedule(state, scheduleId);
  if (!_schedule) {
    return;
  }

  const kendraIds = _schedule.kendras || [];
  let kendras = kendraIds.map((id) => kendra(state, id) || { _id: id });
  // kendras = kendras.filter((k) => k);
  return orderBy(kendras, (k) => [k.country, k.state, k.sanghat, k.district, k.taluka, k.village]);
});
