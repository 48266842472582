import { config } from '../app/selectors.js';

export const doc = (state, docId) => {
  const dbName = config(state).dreamdbDatabaseName;
  try {
    return state.dreamdb['couchdb-default'][`docs-${dbName}`][docId];
  } catch (e) {
    //ignore
  }
}

export const queryResult = (state, queryId) => {
  const dbName = config(state).dreamdbDatabaseName;
  try {
    return state.dreamdb['couchdb-default'][`queries-${dbName}`][queryId]?.value;
  } catch (e) {
    //ignore
  }
}