import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { lazyReducerEnhancer } from 'pwa-helpers/lazy-reducer-enhancer';
import { persistEnhancer } from '@dw/pwa-helpers/redux-persist-enhancer';

//Redux module
import DeviceInfo from '@dreamworld/device-info';
import app from './app/reducers.js';
import auth from './auth/reducers.js';
import moduleLoader from './module-loader/saga';
import sagaDreamdb from './dreamdb/saga.js';
import appSaga from './app/saga.js';
import dreamdb from '@dw/dreamdb-client';

export const sagaMiddleware = createSagaMiddleware();

const persistOptions = [{ path: 'auth', sharedBetweenTabs: true }];

// Sets up a Chrome extension for time travel debugging.
// See https://github.com/zalmoxisus/redux-devtools-extension for more information.
//
const devCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      shouldHotReload: true,
    })
  : compose;

// Initializes the Redux store with a lazyReducerEnhancer (so that you can
// lazily add reducers after the store has been created) and redux-thunk (so
// that you can dispatch async actions). See the "Redux and state management"
// section of the wiki for more details:
// https://pwa-starter-kit.polymer-project.org/redux-and-state-management
export const store = createStore(
  state => state,
  devCompose(
    lazyReducerEnhancer(combineReducers),
    applyMiddleware(sagaMiddleware),
    persistEnhancer(persistOptions)
  )
);

// Note: Persisted reducers must be added first.
store.addReducers({
  auth,
  app
});

DeviceInfo.initRedux(store);

//connect with redux store
dreamdb.connectToRedux(store);
sagaMiddleware.run(sagaDreamdb);
sagaMiddleware.run(moduleLoader);
sagaMiddleware.run(appSaga);

window.store = store;
export default store;
