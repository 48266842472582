import memoize from 'proxy-memoize';

/**
 * @returns {Boolean} `false` when current logged in user is not Admin.
 */
export const isAdmin = memoize((state) => {
  if (!state.auth.user) {
    return;
  }
  
  return state.auth.user.roles.indexOf('SUPER_ADMIN') !== -1
});

export const apiAuthToken = (state) => state.auth?.apiAuthToken;

export const dreamdbAuthToken = (state) => state.auth?.dreamdbAuthToken;

export const loginRequestInProgress = (state) => state.auth?.loginRequestInProgress;

export const isLoggedIn = (state) => {
  const apiAuthToken = state.auth?.apiAuthToken;
  if (!apiAuthToken) {
    return false;
  } else {
    return true;
  }
};