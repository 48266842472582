import { merge } from 'lodash-es';
import * as actions from './actions.js';

const DEF_CONFIG = {
        apiUrl: 'https://api.smpv.dreamworld.dev',
        dreamDbUrl: 'wss://ws.smpv.dreamworld.dev',
        dreamdbDatabaseName: 'server',
        player: {
          transitionTime: 30, //Number of minutes
        },
      };

const getConfig = () => {
  const envConfig = window.process.env;
  return merge({}, DEF_CONFIG, envConfig); 
}

const INITIAL_STATE = {
  config: getConfig(),
};

const app = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.UPDATE_DRAWER_OPENED:
      return { ...state, drawerOpened: action.opened };
    case actions.UPGRADE_AVAILABLE:
      return { ...state, upgradeStatus: 'AVAILABLE' };
    case actions.UPGRADE:
      return { ...state, upgradeStatus: 'CONFIRMED' };
    default:
      return state;
  }
};

export default app;
