import { BaseElement, html, css } from '../base-element';
import { default as i18next } from '../../localize/i18next.js';

/**
 * Shows permission denied page-view.
 *
 * Usage pattern:
 *  <permission-denied></permission-denied>
 */
export class PermissionDenied extends BaseElement {
  static styles = [
    super.styles,
    css`
      :host {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 24px;
        text-align: center;
      }

      div {
        color: var(--mdc-theme-text-disabled-on-background);
      }
    `,
  ];

  render() {
    return html`
      <smpv-icon .name=${'sentiment_very_dissatisfied'} .size=${40} disabled></smpv-icon>
      <div class="headline5">${i18next.t('permissionDenied.title')}</div>
    `;
  }
}
customElements.define('permission-denied', PermissionDenied);
