import { takeEvery, select, call } from 'redux-saga/effects'
import { ROUTE_CHANGED } from '@dreamworld/router';
import * as routerSelectors from '../router/selectors.js';
import * as kendraActions from '../kendras/actions.js';
import * as kendraSelectors from '../kendras/selectors.js';
import * as schedulesActions from '../schedules/actions.js';
import * as schedulesSelectors from '../schedules/selectors.js';
import * as videosSelectors from '../videos/selectors.js';
import * as authActions from '../auth/actions.js';
import * as authSelectors from '../auth/selectors.js';
window.kendraActions = kendraActions;
window.kendraSelectors = kendraSelectors;
window.schedulesActions = schedulesActions;
window.schedulesSelectors = schedulesSelectors;
window.videosSelectors = videosSelectors;
window.authActions = authActions;
window.authSelectors = authSelectors;
/**
 * Load redux module based on pageName.
 */
function* loadModule() {
  const state = yield select();
  const pageName = routerSelectors.pageName(state);
  const dialogName = routerSelectors.dialogName(state);
  console.log('pageName:', pageName);
  switch (pageName) {
    case 'PLAYERS':
      import('../players/index.js');
      import('../kendras-player/index.js');
      break;
    case 'VIDEOS':
      import('../videos/index.js');
      break;
    case 'KENDRAS':
      import('../kendras/index.js');
      import('../kendras-player/index.js');
      break;
    case 'SCHEDULES':
      import('../schedules/list-page/index.js');
      break;
    case 'SCHEDULE':
      import('../schedules/view-page/index.js');
      break;
  }

  switch(dialogName) {
    case 'KENDRA':
      import('../kendras/view/index.js');
      break;
  }
}

/*
  Starts moduleLoader on each dispatched `ROUTER_ROUTE_CHANGED` action.
*/
function* moduleLoader() {
  yield takeEvery(ROUTE_CHANGED, loadModule);
  yield call(async () => {await import('./init-load.js')});
}

export default moduleLoader;
