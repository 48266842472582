// const INITIAL_STATE = {};

import { LOGIN, LOGIN_DONE, LOGIN_FAILED, LOGOUT } from "./actions";

const INITIAL_STATE = {
  // apiAuthToken: 'Basic xxxxx',
  // dreamdbAuthToken: 'Basic xxxx',
  // user: {
  //   name: 'Admin',
  //   mobile: '9998887770',
  //   roles: ['SUPER_ADMIN'],
  //   authorizedKendraCriteria: undefined,
  //   active: true,
  // },
  // loginRequestInProgress: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN:
      return { ...state, loginRequestInProgress: true };
    case LOGIN_DONE:
      return {
        ...state,
        loginRequestInProgress: false,
        apiAuthToken: action.authToken,
        dreamdbAuthToken: action.dreamdbAuthToken,
        user: {
          name: 'Admin',
          mobile: '9998887770',
          roles: ['SUPER_ADMIN'],
          authorizedKendraCriteria: undefined,
          active: true,
        },
      };
    case LOGIN_FAILED:
      return { ...state, loginRequestInProgress: false };
    case LOGOUT:
      return {};
    default:
      return state;
  }
};
