import get from 'lodash-es/get';

/**
 * @param {Object} state Redux state.
 * @returns {String} Current page name.
 */
export const pageName = (state) => get(state, `router.page.name`, '');
export const pageParams = (state) => get(state, `router.page.params`, {});

export const dialogName = (state) => get(state, `router.dialog.name`, '');
export const dialogParams = (state) => get(state, `router.dialog.params`, {});
