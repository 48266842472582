export const UPDATE_DRAWER_OPENED = 'APP_UPDATE_DRAWER_OPENED';

export const UPGRADE_AVAILABLE = 'APP_UPGRADE_AVAILABLE';
export const UPGRADE = 'APP_UPGRADE';

/**
 * Opens or closes drawer.
 * @param {Boolean} opened `true` when drawer is opened.
 */
export const updateDrawerOpened = (opened) => {
  return {
    type: UPDATE_DRAWER_OPENED,
    opened,
  };
};
