export const LOGIN = 'AUTH_LOGIN';
export const LOGIN_DONE = 'AUTH_LOGIN_DONE';
export const LOGIN_FAILED = 'AUTH_LOGIN_FAILED';
export const LOGOUT = 'AUTH_LOGOUT';

export const login = (username, password) => {
  return { type: LOGIN, username, password };
};

export const loginDone = (authToken, dreamdbAuthToken) => {
  return { type: LOGIN_DONE, authToken, dreamdbAuthToken };
};

export const loginFailed = (error) => {
  return { type: LOGIN_FAILED, error};
};

export const logout = () => {
  return { type: LOGOUT };
};
