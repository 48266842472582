import { svg } from 'lit';

export const loginLogo = svg`
  <svg width="375" height="375" viewBox="0 0 348 350" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M347.25 272.79H0.25V272.964H347.25V272.79Z" fill="#EBEBEB"/>
  <path d="M312.48 283.939H289.495V284.113H312.48V283.939Z" fill="#EBEBEB"/>
  <path d="M230.117 285.824H224.086V285.998H230.117V285.824Z" fill="#EBEBEB"/>
  <path d="M288.801 277.509H275.483V277.682H288.801V277.509Z" fill="#EBEBEB"/>
  <path d="M66.6311 278.673H36.6572V278.846H66.6311V278.673Z" fill="#EBEBEB"/>
  <path d="M77.2077 278.673H72.8147V278.846H77.2077V278.673Z" fill="#EBEBEB"/>
  <path d="M156.504 281.598H91.4902V281.771H156.504V281.598Z" fill="#EBEBEB"/>
  <path d="M164.728 241.884H30.7236C29.6738 241.882 28.6677 241.465 27.926 240.723C27.1844 239.981 26.7678 238.975 26.7678 237.927V49.8381C26.777 48.796 27.1975 47.7995 27.9382 47.0651C28.6789 46.3307 29.6798 45.9178 30.7236 45.916H164.728C165.779 45.916 166.787 46.3329 167.53 47.0749C168.273 47.817 168.691 48.8234 168.691 49.8728V237.927C168.691 238.977 168.273 239.983 167.53 240.725C166.787 241.467 165.779 241.884 164.728 241.884ZM30.7236 46.0546C29.7199 46.0564 28.7578 46.4559 28.0487 47.1652C27.3396 47.8746 26.9413 48.8359 26.9413 49.8381V237.927C26.9413 238.93 27.3396 239.891 28.0487 240.6C28.7578 241.31 29.7199 241.709 30.7236 241.711H164.728C165.732 241.709 166.695 241.31 167.405 240.601C168.116 239.891 168.515 238.93 168.517 237.927V49.8381C168.515 48.8353 168.116 47.874 167.405 47.1648C166.695 46.4556 165.732 46.0564 164.728 46.0546H30.7236Z" fill="#EBEBEB"/>
  <path d="M314.847 241.884H180.836C179.785 241.882 178.779 241.465 178.036 240.723C177.293 239.981 176.875 238.976 176.873 237.927V49.8381C176.884 48.7953 177.306 47.7988 178.048 47.0647C178.79 46.3305 179.791 45.9178 180.836 45.916H314.847C315.89 45.9196 316.889 46.3333 317.628 47.0675C318.367 47.8017 318.787 48.7972 318.796 49.8381V237.927C318.796 238.974 318.38 239.979 317.64 240.72C316.9 241.462 315.896 241.88 314.847 241.884ZM180.836 46.0546C179.831 46.0564 178.869 46.4556 178.158 47.1648C177.448 47.874 177.048 48.8353 177.047 49.8381V237.927C177.048 238.93 177.448 239.891 178.158 240.601C178.869 241.31 179.831 241.709 180.836 241.711H314.847C315.852 241.709 316.814 241.31 317.524 240.601C318.235 239.891 318.635 238.93 318.636 237.927V49.8381C318.635 48.8353 318.235 47.874 317.524 47.1648C316.814 46.4556 315.852 46.0564 314.847 46.0546H180.836Z" fill="#EBEBEB"/>
  <path d="M219.929 203.529H303.313C303.313 205 302.744 206.414 301.725 207.477C300.707 208.539 299.316 209.168 297.845 209.232C288.885 209.641 275.095 211.055 270.73 216.003C262.576 225.233 263.367 257.649 273.131 265.154C276.185 267.51 285.11 272.18 285.11 272.18H238.133C238.133 272.18 246.461 267.413 249.369 265.154C259.085 257.587 259.355 225.191 251.166 216.003C246.967 211.29 234.045 209.766 225.377 209.288C223.904 209.206 222.517 208.564 221.504 207.492C220.491 206.421 219.927 205.003 219.929 203.529Z" fill="#F5F5F5"/>
  <path d="M292.979 209.537C284.512 210.23 274.366 211.886 270.729 216.002C269.542 217.424 268.603 219.036 267.953 220.77H253.969C253.31 219.04 252.373 217.43 251.193 216.002C247.543 211.907 237.313 210.223 228.985 209.537H292.979Z" fill="#E6E6E6"/>
  <path d="M255.448 203.57L256.586 190.633H240.145L239.014 203.57H255.448Z" fill="#EBEBEB"/>
  <path d="M255.448 203.57L256.586 190.633H257.467L256.336 203.57H255.448Z" fill="#E0E0E0"/>
  <path d="M253.886 202.253L254.788 191.949H241.706L240.804 202.253H253.886Z" fill="#F0F0F0"/>
  <path d="M252.887 201.401L253.637 192.802H242.713L241.957 201.401H252.887Z" fill="#FAFAFA"/>
  <path d="M260.777 203.53H277.288L275.456 182.623H258.945L260.777 203.53Z" fill="#E6E6E6"/>
  <path d="M260.778 203.53H259.993L258.161 182.623H258.945L260.778 203.53Z" fill="#E0E0E0"/>
  <path d="M262.277 201.402H275.421L273.964 184.757H260.819L262.277 201.402Z" fill="#F0F0F0"/>
  <path d="M263.235 200.03H274.221L272.999 186.129H262.02L263.235 200.03Z" fill="#FAFAFA"/>
  <path d="M268.786 192.379C268.609 192.557 268.398 192.698 268.166 192.795C267.933 192.891 267.684 192.941 267.433 192.941C267.181 192.941 266.932 192.891 266.7 192.795C266.468 192.698 266.257 192.557 266.079 192.379C265.723 192.02 265.523 191.536 265.523 191.031C265.523 190.526 265.723 190.041 266.079 189.683C266.439 189.327 266.926 189.127 267.433 189.127C267.94 189.127 268.426 189.327 268.786 189.683C269.142 190.041 269.342 190.526 269.342 191.031C269.342 191.536 269.142 192.02 268.786 192.379Z" fill="#EBEBEB"/>
  <path d="M267.975 194.721C268.104 194.59 268.258 194.486 268.428 194.415C268.598 194.344 268.78 194.308 268.964 194.308C269.148 194.308 269.33 194.344 269.5 194.415C269.67 194.486 269.824 194.59 269.953 194.721C270.153 194.914 270.291 195.163 270.348 195.435C270.405 195.708 270.379 195.991 270.273 196.248C270.166 196.505 269.985 196.724 269.752 196.877C269.519 197.03 269.246 197.109 268.967 197.105C268.688 197.111 268.414 197.032 268.18 196.88C267.946 196.728 267.763 196.509 267.656 196.251C267.549 195.994 267.522 195.71 267.579 195.437C267.636 195.164 267.774 194.915 267.975 194.721Z" fill="#F0F0F0"/>
  <path d="M56.9636 179.698H134.823V93.7163L56.9636 93.7163L56.9636 179.698Z" fill="#E0E0E0"/>
  <path d="M54.4793 179.698H135.233V93.7163L54.4793 93.7163L54.4793 179.698Z" fill="#F0F0F0"/>
  <path d="M57.9008 175.741H131.951V96.4532L57.9008 96.4532L57.9008 175.741Z" fill="white"/>
  <path d="M57.8939 175.741H60.0383L60.0383 96.4532H57.8939L57.8939 175.741Z" fill="#E0E0E0"/>
  <path d="M59.8091 153.47V155.611H132.138V153.47H59.8091Z" fill="#E0E0E0"/>
  <path d="M57.8936 153.47H133.095V149.88H57.8936V153.47Z" fill="#F0F0F0"/>
  <path d="M262.853 108.435C262.853 108.435 263.547 103.861 260.209 100.93C256.87 97.9987 257.162 96.2524 258.127 96.0237C259.091 95.7951 259.959 98.7332 261.43 99.537C261.43 99.537 261.479 95.3793 259.987 93.8201C258.494 92.261 257.71 88.3181 259.133 88.2765C260.556 88.2349 260.694 90.6325 260.993 92.3511C261.291 94.0696 262.867 96.5088 262.867 96.5088C262.867 96.5088 263.172 92.7668 264.137 91.2285C265.101 89.6901 262.534 86.1699 263.526 84.6315C264.518 83.0932 266.302 86.0175 266.128 89.3229C265.955 92.6283 263.783 96.1415 264.296 99.5855C264.296 99.5855 265.268 97.1671 266.135 96.9454C267.003 96.7236 267.891 95.1298 268.474 94.5339C269.057 93.9379 269.8 95.996 268.474 97.9017C267.149 99.8073 263.783 104.907 264.31 109.024L262.853 108.435Z" fill="#E6E6E6"/>
  <path d="M260.854 106.355L259.757 113.209C259.674 113.709 259.701 114.221 259.838 114.71C259.975 115.198 260.217 115.651 260.548 116.035C260.879 116.42 261.29 116.727 261.753 116.936C262.216 117.144 262.719 117.249 263.227 117.242H264.393C264.901 117.249 265.404 117.144 265.867 116.936C266.33 116.727 266.741 116.42 267.072 116.035C267.403 115.651 267.645 115.198 267.782 114.71C267.919 114.221 267.946 113.709 267.863 113.209L266.746 106.355H260.854Z" fill="#F5F5F5"/>
  <path d="M277.392 114.713H200.081V118.677H277.392V114.713Z" fill="#E6E6E6"/>
  <path d="M207.048 100.092H203.641V114.713H207.048V100.092Z" fill="#F0F0F0"/>
  <path d="M223.517 100.092H220.109V114.713H223.517V100.092Z" fill="#F0F0F0"/>
  <path d="M242.216 99.7199L238.858 100.298L241.346 114.707L244.704 114.129L242.216 99.7199Z" fill="#F0F0F0"/>
  <path d="M211.267 102.018H207.86V114.713H211.267V102.018Z" fill="#F0F0F0"/>
  <path d="M219.096 102.018H215.688V114.713H219.096V102.018Z" fill="#F0F0F0"/>
  <path d="M238.861 102.018H235.454V114.713H238.861V102.018Z" fill="#F0F0F0"/>
  <path d="M227.989 101.568L224.663 102.307L227.421 114.699L230.748 113.961L227.989 101.568Z" fill="#F0F0F0"/>
  <path d="M214.21 99.3633H212.51V114.712H214.21V99.3633Z" fill="#F0F0F0"/>
  <path d="M234.468 99.3633H232.768V114.712H234.468V99.3633Z" fill="#F0F0F0"/>
  <path d="M200.087 148.286H277.399V144.322H200.087V148.286Z" fill="#E6E6E6"/>
  <path d="M270.431 144.323H273.839V129.702H270.431V144.323Z" fill="#F0F0F0"/>
  <path d="M253.962 144.323H257.37V129.702H253.962V144.323Z" fill="#F0F0F0"/>
  <path d="M232.784 143.731L236.142 144.309L238.629 129.9L235.271 129.322L232.784 143.731Z" fill="#F0F0F0"/>
  <path d="M266.212 144.323H269.619V131.628H266.212V144.323Z" fill="#F0F0F0"/>
  <path d="M258.383 144.323H261.791V131.628H258.383V144.323Z" fill="#F0F0F0"/>
  <path d="M238.618 144.323H242.025V131.628H238.618V144.323Z" fill="#F0F0F0"/>
  <path d="M246.74 143.569L250.066 144.308L252.824 131.915L249.498 131.177L246.74 143.569Z" fill="#F0F0F0"/>
  <path d="M263.269 144.323H264.969V128.974H263.269V144.323Z" fill="#F0F0F0"/>
  <path d="M243.011 144.323H244.711V128.974H243.011V144.323Z" fill="#F0F0F0"/>
  <path d="M207.506 144.191H224.1V132.751H207.506V144.191Z" fill="#F5F5F5"/>
  <path d="M173.75 304.084C248.066 304.084 308.31 300.572 308.31 296.24C308.31 291.907 248.066 288.396 173.75 288.396C99.4349 288.396 39.1904 291.907 39.1904 296.24C39.1904 300.572 99.4349 304.084 173.75 304.084Z" fill="#F5F5F5"/>
  <path d="M230.984 278.908C230.984 278.908 227.514 271.424 231.47 264.038C235.426 256.651 236.536 254.918 234.038 253.408C231.539 251.897 230.38 259.346 230.38 259.346C229.137 255.995 229.008 252.333 230.013 248.903C231.755 243.561 232.851 234.864 230.471 233.929C228.09 232.993 230.02 244.78 227.951 248.799C226.899 245.98 226.22 243.036 225.932 240.04C225.647 235.751 226.092 228.898 224.211 227.145C222.33 225.391 221.129 226.597 222.976 236.07C224.085 241.725 224.698 247.466 224.808 253.227C224.808 253.227 220.734 240.81 217.993 240.449C215.251 240.089 215.258 244.108 217.604 247.594C219.95 251.079 224.468 257.33 225.203 260.545C225.203 260.545 220.727 251.204 216.702 254.066C212.677 256.928 220.206 262.575 223.565 264.613C226.924 266.65 229.256 279.435 229.256 279.435L230.984 278.908Z" fill="#02AFCD"/>
  <path opacity="0.2" d="M230.984 278.908C230.984 278.908 227.514 271.424 231.47 264.038C235.426 256.651 236.536 254.918 234.038 253.408C231.539 251.897 230.38 259.346 230.38 259.346C229.137 255.995 229.008 252.333 230.013 248.903C231.755 243.561 232.851 234.864 230.471 233.929C228.09 232.993 230.02 244.78 227.951 248.799C226.899 245.98 226.22 243.036 225.932 240.04C225.647 235.751 226.092 228.898 224.211 227.145C222.33 225.391 221.129 226.597 222.976 236.07C224.085 241.725 224.698 247.466 224.808 253.227C224.808 253.227 220.734 240.81 217.993 240.449C215.251 240.089 215.258 244.108 217.604 247.594C219.95 251.079 224.468 257.33 225.203 260.545C225.203 260.545 220.727 251.204 216.702 254.066C212.677 256.928 220.206 262.575 223.565 264.613C226.924 266.65 229.256 279.435 229.256 279.435L230.984 278.908Z" fill="black"/>
  <path d="M228.271 281.978C228.271 281.978 231.616 274.439 227.577 267.121C223.538 259.804 222.399 258.071 224.884 256.526C227.368 254.981 228.604 262.375 228.604 262.375C229.807 259.008 229.893 255.344 228.847 251.925C227.042 246.617 225.842 237.934 228.215 236.964C230.589 235.994 228.798 247.809 230.908 251.793C231.929 248.958 232.573 246.002 232.823 243C233.059 238.71 232.532 231.864 234.392 230.083C236.252 228.302 237.466 229.494 235.731 238.987C234.688 244.658 234.145 250.408 234.107 256.173C234.107 256.173 238.035 243.7 240.77 243.298C243.504 242.896 243.546 246.915 241.241 250.435C238.937 253.955 234.503 260.275 233.795 263.497C233.795 263.497 238.16 254.087 242.22 256.893C246.28 259.7 238.819 265.451 235.488 267.544C232.157 269.637 229.978 282.45 229.978 282.45L228.271 281.978Z" fill="#02AFCD"/>
  <path d="M243.705 276.607H216.056L218.298 296.364H241.47L243.705 276.607Z" fill="#263238"/>
  <path opacity="0.2" d="M298.011 80.1484H151.882C150.931 80.1484 150.161 80.9178 150.161 81.867V211.464C150.161 212.413 150.931 213.182 151.882 213.182H298.011C298.961 213.182 299.732 212.413 299.732 211.464V81.867C299.732 80.9178 298.961 80.1484 298.011 80.1484Z" fill="black"/>
  <path d="M292.354 77.6885H146.226C145.275 77.6885 144.504 78.4579 144.504 79.407V209.004C144.504 209.953 145.275 210.722 146.226 210.722H292.354C293.305 210.722 294.075 209.953 294.075 209.004V79.407C294.075 78.4579 293.305 77.6885 292.354 77.6885Z" fill="#02AFCD"/>
  <path opacity="0.6" d="M144.505 89.5723V209.01C144.469 209.42 144.594 209.828 144.853 210.148C145.111 210.469 145.484 210.677 145.893 210.729H292.646C293.063 210.687 293.446 210.483 293.713 210.161C293.981 209.84 294.111 209.427 294.076 209.01V89.5723H144.505Z" fill="white"/>
  <path d="M292.299 96.5781H146.275V202.095H292.299V96.5781Z" fill="white"/>
  <path opacity="0.6" d="M289.19 85.9073C290.401 85.9073 291.383 84.7252 291.383 83.2671C291.383 81.809 290.401 80.627 289.19 80.627C287.979 80.627 286.997 81.809 286.997 83.2671C286.997 84.7252 287.979 85.9073 289.19 85.9073Z" fill="white"/>
  <path opacity="0.6" d="M282.007 85.9073C283.219 85.9073 284.201 84.7252 284.201 83.2671C284.201 81.809 283.219 80.627 282.007 80.627C280.796 80.627 279.814 81.809 279.814 83.2671C279.814 84.7252 280.796 85.9073 282.007 85.9073Z" fill="white"/>
  <path opacity="0.6" d="M277.01 83.2671C277.01 84.7223 276.032 85.9003 274.817 85.9003C273.603 85.9003 272.631 84.7223 272.631 83.2671C272.631 81.8119 273.61 80.627 274.817 80.627C276.025 80.627 277.01 81.805 277.01 83.2671Z" fill="white"/>
  <path d="M177.386 108.899V136.112L170.377 144.552H154.852V108.899H177.386Z" fill="#02AFCD"/>
  <path opacity="0.7" d="M177.386 108.899V136.112L170.377 144.552H154.852V108.899H177.386Z" fill="white"/>
  <path opacity="0.3" d="M177.387 136.111L170.377 144.552V136.111H177.387Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M173.896 114.041H158.35V115.06H173.896V114.041Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M173.896 117.908H158.35V118.927H173.896V117.908Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M173.896 121.781H158.35V122.807H173.896V121.781Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M173.896 125.655H158.35V126.674H173.896V125.655Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M169.878 129.528H158.35V130.554H169.878V129.528Z" fill="#02AFCD"/>
  <path d="M213.127 108.899V136.112L206.118 144.552H190.593V108.899H213.127Z" fill="#02AFCD"/>
  <path opacity="0.7" d="M213.127 108.899V136.112L206.118 144.552H190.593V108.899H213.127Z" fill="white"/>
  <path opacity="0.3" d="M213.128 136.111L206.118 144.552V136.111H213.128Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M209.63 114.041H194.084V115.06H209.63V114.041Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M209.63 117.908H194.084V118.927H209.63V117.908Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M209.63 121.781H194.084V122.807H209.63V121.781Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M209.63 125.655H194.084V126.674H209.63V125.655Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M205.612 129.528H194.084V130.554H205.612V129.528Z" fill="#02AFCD"/>
  <path d="M248.869 108.899V136.112L241.859 144.552H226.334V108.899H248.869Z" fill="#02AFCD"/>
  <path opacity="0.7" d="M248.869 108.899V136.112L241.859 144.552H226.334V108.899H248.869Z" fill="white"/>
  <path opacity="0.3" d="M248.869 136.111L241.859 144.552V136.111H248.869Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M245.371 114.041H229.825V115.06H245.371V114.041Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M245.371 117.908H229.825V118.927H245.371V117.908Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M245.371 121.781H229.825V122.807H245.371V121.781Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M245.371 125.655H229.825V126.674H245.371V125.655Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M241.353 129.528H229.825V130.554H241.353V129.528Z" fill="#02AFCD"/>
  <path d="M284.61 108.899V136.112L277.594 144.552H262.076V108.899H284.61Z" fill="#02AFCD"/>
  <path opacity="0.7" d="M284.61 108.899V136.112L277.594 144.552H262.076V108.899H284.61Z" fill="white"/>
  <path opacity="0.3" d="M284.61 136.111L277.593 144.552V136.111H284.61Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M281.112 114.041H265.566V115.06H281.112V114.041Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M281.112 117.908H265.566V118.927H281.112V117.908Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M281.112 121.781H265.566V122.807H281.112V121.781Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M281.112 125.655H265.566V126.674H281.112V125.655Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M277.094 129.528H265.566V130.554H277.094V129.528Z" fill="#02AFCD"/>
  <path d="M177.386 156.021V183.233L170.377 191.673H154.852V156.021H177.386Z" fill="#02AFCD"/>
  <path opacity="0.7" d="M177.386 156.021V183.233L170.377 191.673H154.852V156.021H177.386Z" fill="white"/>
  <path opacity="0.3" d="M177.387 183.232L170.377 191.673V183.232H177.387Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M173.896 161.162H158.35V162.181H173.896V161.162Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M173.896 165.029H158.35V166.048H173.896V165.029Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M173.896 168.902H158.35V169.928H173.896V168.902Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M173.896 172.776H158.35V173.802H173.896V172.776Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M169.878 176.649H158.35V177.668H169.878V176.649Z" fill="#02AFCD"/>
  <path d="M213.127 156.021V183.233L206.118 191.673H190.593V156.021H213.127Z" fill="#02AFCD"/>
  <path opacity="0.7" d="M213.127 156.021V183.233L206.118 191.673H190.593V156.021H213.127Z" fill="white"/>
  <path opacity="0.3" d="M213.128 183.232L206.118 191.673V183.232H213.128Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M209.63 161.162H194.084V162.181H209.63V161.162Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M209.63 165.029H194.084V166.048H209.63V165.029Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M209.63 168.902H194.084V169.928H209.63V168.902Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M209.63 172.776H194.084V173.802H209.63V172.776Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M205.612 176.649H194.084V177.668H205.612V176.649Z" fill="#02AFCD"/>
  <path d="M248.869 156.021V183.233L241.859 191.673H226.334V156.021H248.869Z" fill="#02AFCD"/>
  <path opacity="0.7" d="M248.869 156.021V183.233L241.859 191.673H226.334V156.021H248.869Z" fill="white"/>
  <path opacity="0.3" d="M248.869 183.232L241.859 191.673V183.232H248.869Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M245.371 161.162H229.825V162.181H245.371V161.162Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M245.371 165.029H229.825V166.048H245.371V165.029Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M245.371 168.902H229.825V169.928H245.371V168.902Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M245.371 172.776H229.825V173.802H245.371V172.776Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M241.353 176.649H229.825V177.668H241.353V176.649Z" fill="#02AFCD"/>
  <path d="M284.61 156.021V183.233L277.594 191.673H262.076V156.021H284.61Z" fill="#02AFCD"/>
  <path opacity="0.7" d="M284.61 156.021V183.233L277.594 191.673H262.076V156.021H284.61Z" fill="white"/>
  <path opacity="0.3" d="M284.61 183.232L277.593 191.673V183.232H284.61Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M281.112 161.162H265.566V162.181H281.112V161.162Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M281.112 165.029H265.566V166.048H281.112V165.029Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M281.112 168.902H265.566V169.928H281.112V168.902Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M281.112 172.776H265.566V173.802H281.112V172.776Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M277.094 176.649H265.566V177.668H277.094V176.649Z" fill="#02AFCD"/>
  <path opacity="0.1" d="M229.242 133.471H193.272C191.605 133.471 190.253 134.82 190.253 136.485V163.399C190.253 165.064 191.605 166.414 193.272 166.414H229.242C230.91 166.414 232.261 165.064 232.261 163.399V136.485C232.261 134.82 230.91 133.471 229.242 133.471Z" fill="black"/>
  <g opacity="0.1">
  <path d="M226.667 131.939H222.503V127.442C222.503 124.601 221.373 121.876 219.361 119.867C217.349 117.858 214.62 116.729 211.774 116.729C208.929 116.729 206.199 117.858 204.187 119.867C202.175 121.876 201.045 124.601 201.045 127.442V131.939H196.881V127.442C196.881 123.498 198.45 119.716 201.243 116.927C204.036 114.138 207.824 112.571 211.774 112.571C215.724 112.571 219.512 114.138 222.305 116.927C225.098 119.716 226.667 123.498 226.667 127.442V131.939Z" fill="black"/>
  </g>
  <path d="M211.892 132.085H175.922C174.255 132.085 172.903 133.435 172.903 135.099V162.014C172.903 163.679 174.255 165.028 175.922 165.028H211.892C213.56 165.028 214.911 163.679 214.911 162.014V135.099C214.911 133.435 213.56 132.085 211.892 132.085Z" fill="#263238"/>
  <path opacity="0.2" d="M204.293 132.085H175.922C174.255 132.085 172.903 133.435 172.903 135.099V162.014C172.903 163.679 174.255 165.028 175.922 165.028H204.293C205.96 165.028 207.312 163.679 207.312 162.014V135.099C207.312 133.435 205.96 132.085 204.293 132.085Z" fill="white"/>
  <path d="M192.217 144.447C192.217 142.174 190.829 140.331 189.122 140.331C187.415 140.331 186.034 142.174 186.034 144.447C185.993 145.196 186.15 145.943 186.49 146.612C186.83 147.281 187.34 147.849 187.97 148.258L187.394 156.851H190.864L190.295 148.258C190.921 147.846 191.428 147.278 191.765 146.609C192.103 145.94 192.259 145.195 192.217 144.447Z" fill="#263238"/>
  <path d="M209.317 130.554H205.153V126.056C205.153 123.215 204.023 120.49 202.011 118.481C199.999 116.472 197.27 115.343 194.424 115.343C191.579 115.343 188.85 116.472 186.838 118.481C184.825 120.49 183.695 123.215 183.695 126.056V130.554H179.531V126.056C179.531 122.112 181.1 118.33 183.893 115.541C186.686 112.752 190.474 111.186 194.424 111.186C198.374 111.186 202.162 112.752 204.955 115.541C207.748 118.33 209.317 122.112 209.317 126.056V130.554Z" fill="#263238"/>
  <g opacity="0.4">
  <path d="M209.317 130.554H205.153V126.056C205.153 123.215 204.023 120.49 202.011 118.481C199.999 116.472 197.27 115.343 194.424 115.343C191.579 115.343 188.85 116.472 186.838 118.481C184.825 120.49 183.695 123.215 183.695 126.056V130.554H179.531V126.056C179.531 122.112 181.1 118.33 183.893 115.541C186.686 112.752 190.474 111.186 194.424 111.186C198.374 111.186 202.162 112.752 204.955 115.541C207.748 118.33 209.317 122.112 209.317 126.056V130.554Z" fill="white"/>
  </g>
  <path opacity="0.2" d="M209.317 126.056V130.554H206.493V125.544C206.493 123.76 206.141 121.993 205.457 120.345C204.773 118.696 203.77 117.199 202.506 115.937C201.243 114.676 199.742 113.676 198.091 112.994C196.44 112.311 194.67 111.961 192.884 111.962C190.153 111.953 187.486 112.788 185.25 114.352C187.446 112.632 190.083 111.564 192.859 111.27C195.635 110.975 198.438 111.466 200.948 112.687C203.457 113.908 205.572 115.809 207.051 118.173C208.53 120.537 209.313 123.269 209.31 126.056H209.317Z" fill="white"/>
  <path d="M150.473 205.732H133.651V205.088H137.447C137.702 205.085 137.949 204.994 138.144 204.829C138.339 204.665 138.47 204.438 138.516 204.187L140.216 194.084L140.549 192.095H146.309L151.466 204.235C151.536 204.399 151.564 204.577 151.548 204.755C151.532 204.932 151.472 205.103 151.374 205.251C151.275 205.399 151.141 205.521 150.984 205.605C150.827 205.689 150.651 205.733 150.473 205.732Z" fill="#02AFCD"/>
  <path opacity="0.4" d="M150.473 205.732H133.651V205.088H137.447C137.702 205.085 137.949 204.994 138.144 204.829C138.339 204.665 138.47 204.438 138.516 204.187L140.216 194.084L140.549 192.095H146.309L151.466 204.235C151.536 204.399 151.564 204.577 151.548 204.755C151.532 204.932 151.472 205.103 151.374 205.251C151.275 205.399 151.141 205.521 150.984 205.605C150.827 205.689 150.651 205.733 150.473 205.732Z" fill="white"/>
  <path opacity="0.2" d="M150.494 205.088H137.447C137.702 205.086 137.949 204.994 138.144 204.83C138.339 204.665 138.471 204.438 138.516 204.187L140.216 194.084H146.254L150.848 204.555C150.874 204.613 150.884 204.677 150.879 204.74C150.873 204.803 150.852 204.864 150.817 204.917C150.781 204.971 150.733 205.014 150.677 205.044C150.621 205.074 150.558 205.089 150.494 205.088Z" fill="black"/>
  <path d="M159.516 195.816H125.864C125.787 195.82 125.711 195.805 125.641 195.774C125.571 195.743 125.51 195.695 125.461 195.636C125.413 195.577 125.379 195.507 125.362 195.433C125.345 195.358 125.346 195.281 125.364 195.206L130.153 169.955C130.188 169.793 130.274 169.646 130.399 169.536C130.524 169.426 130.681 169.359 130.847 169.345H164.492C164.568 169.342 164.645 169.356 164.714 169.387C164.784 169.419 164.846 169.466 164.894 169.525C164.942 169.585 164.975 169.655 164.991 169.729C165.007 169.804 165.005 169.881 164.985 169.955L160.21 195.199C160.176 195.363 160.091 195.512 159.965 195.623C159.84 195.734 159.683 195.802 159.516 195.816Z" fill="#02AFCD"/>
  <path opacity="0.4" d="M159.516 195.816H125.864C125.787 195.82 125.711 195.805 125.641 195.774C125.571 195.743 125.51 195.695 125.461 195.636C125.413 195.577 125.379 195.507 125.362 195.433C125.345 195.358 125.346 195.281 125.364 195.206L130.153 169.955C130.188 169.793 130.274 169.646 130.399 169.536C130.524 169.426 130.681 169.359 130.847 169.345H164.492C164.568 169.342 164.645 169.356 164.714 169.387C164.784 169.419 164.846 169.466 164.894 169.525C164.942 169.585 164.975 169.655 164.991 169.729C165.007 169.804 165.005 169.881 164.985 169.955L160.21 195.199C160.176 195.363 160.091 195.512 159.965 195.623C159.84 195.734 159.683 195.802 159.516 195.816Z" fill="white"/>
  <path d="M158.822 195.816H125.17C125.094 195.82 125.017 195.805 124.947 195.774C124.878 195.743 124.816 195.695 124.767 195.636C124.719 195.577 124.685 195.507 124.668 195.433C124.651 195.358 124.652 195.281 124.67 195.206L129.459 169.955C129.494 169.793 129.58 169.646 129.705 169.536C129.83 169.426 129.987 169.359 130.153 169.345H163.798C163.875 169.342 163.951 169.356 164.021 169.387C164.09 169.419 164.152 169.466 164.2 169.525C164.248 169.585 164.281 169.655 164.297 169.729C164.313 169.804 164.311 169.881 164.291 169.955L159.516 195.199C159.482 195.363 159.397 195.512 159.272 195.623C159.147 195.734 158.989 195.802 158.822 195.816Z" fill="#263238"/>
  <path d="M158.378 194.264H126.204L130.632 170.904H162.799L158.378 194.264Z" fill="#02AFCD"/>
  <path d="M102.303 205.732V204.943L115.253 202.33H140.591C141.042 202.33 141.474 202.509 141.793 202.827C142.112 203.146 142.291 203.578 142.291 204.028C142.291 204.479 142.112 204.911 141.793 205.231C141.475 205.55 141.042 205.731 140.591 205.732H102.303Z" fill="#02AFCD"/>
  <path opacity="0.4" d="M142.291 204.035C142.292 204.484 142.115 204.915 141.798 205.234C141.477 205.552 141.043 205.731 140.591 205.732H102.303V204.949L115.26 202.33H140.591C140.815 202.33 141.036 202.374 141.243 202.46C141.45 202.546 141.637 202.671 141.795 202.83C141.953 202.988 142.079 203.176 142.164 203.383C142.249 203.59 142.292 203.811 142.291 204.035Z" fill="white"/>
  <path opacity="0.4" d="M142.291 204.035C142.292 204.484 142.115 204.915 141.798 205.234C141.477 205.552 141.043 205.731 140.591 205.732H125.205V204.949L138.162 202.33H140.591C140.814 202.33 141.036 202.374 141.243 202.46C141.45 202.546 141.637 202.671 141.795 202.83C141.953 202.988 142.078 203.176 142.163 203.383C142.248 203.59 142.292 203.811 142.291 204.035Z" fill="white"/>
  <path d="M151.549 296.364H155.019L161.244 209.786H156.643L151.549 296.364Z" fill="#263238"/>
  <path opacity="0.1" d="M151.549 296.364H155.019L160.939 214.083L161.244 209.925H156.643L156.4 214.083L151.549 296.364Z" fill="white"/>
  <path opacity="0.2" d="M156.4 213.875H160.939L161.244 209.925H156.643L156.4 213.875Z" fill="black"/>
  <path d="M126.114 296.364H129.577L130.146 209.786H125.545L126.114 296.364Z" fill="#263238"/>
  <path opacity="0.1" d="M125.545 209.925L125.635 223.895L126.107 296.364H129.577L130.049 223.895L130.139 209.925H125.545Z" fill="white"/>
  <path opacity="0.2" d="M125.545 209.925L125.635 224.047H130.049L130.139 209.925H125.545Z" fill="black"/>
  <path d="M89.311 296.364H92.7741L99.0062 209.786H94.405L89.311 296.364Z" fill="#263238"/>
  <path opacity="0.1" d="M89.304 296.364H92.774L98.7007 214.083L99.0061 209.925H94.4048L94.155 214.083L89.304 296.364Z" fill="white"/>
  <path opacity="0.2" d="M94.155 213.875H98.7007L99.0061 209.925H94.4049L94.155 213.875Z" fill="black"/>
  <path d="M191.204 296.364H194.667L189.573 209.786H184.979L191.204 296.364Z" fill="#263238"/>
  <path opacity="0.1" d="M184.979 209.925L185.284 214.083L191.204 296.364H194.667L189.823 214.083L189.573 209.925H184.979Z" fill="white"/>
  <path opacity="0.2" d="M189.573 209.925H184.979L185.284 213.875H189.823L189.573 209.925Z" fill="black"/>
  <path d="M191.718 211.948H92.2607C92.0638 211.948 91.8689 211.91 91.687 211.834C91.5051 211.759 91.3399 211.649 91.2007 211.51C91.0615 211.371 90.9511 211.206 90.8757 211.024C90.8004 210.843 90.7616 210.648 90.7616 210.451V207.61H193.217V210.451C193.217 210.648 193.178 210.843 193.103 211.024C193.027 211.206 192.917 211.371 192.778 211.51C192.639 211.649 192.473 211.759 192.291 211.834C192.11 211.91 191.915 211.948 191.718 211.948Z" fill="#02AFCD"/>
  <path opacity="0.4" d="M191.718 211.948H92.2607C92.0638 211.948 91.8689 211.91 91.687 211.834C91.5051 211.759 91.3399 211.649 91.2007 211.51C91.0615 211.371 90.9511 211.206 90.8757 211.024C90.8004 210.843 90.7616 210.648 90.7616 210.451V207.61H193.217V210.451C193.217 210.648 193.178 210.843 193.103 211.024C193.027 211.206 192.917 211.371 192.778 211.51C192.639 211.649 192.473 211.759 192.291 211.834C192.11 211.91 191.915 211.948 191.718 211.948Z" fill="white"/>
  <path opacity="0.2" d="M90.7546 209.779H193.217V207.61H90.7546V209.779Z" fill="black"/>
  <path d="M88.6101 208.178H195.368C195.692 208.178 196.003 208.05 196.232 207.821C196.461 207.593 196.59 207.282 196.59 206.959C196.59 206.635 196.461 206.325 196.232 206.096C196.003 205.868 195.692 205.739 195.368 205.739H88.6101C88.2862 205.739 87.9755 205.868 87.7464 206.096C87.5174 206.325 87.3887 206.635 87.3887 206.959C87.3887 207.282 87.5174 207.593 87.7464 207.821C87.9755 208.05 88.2862 208.178 88.6101 208.178Z" fill="#02AFCD"/>
  <path opacity="0.4" d="M88.6101 208.178H195.368C195.692 208.178 196.003 208.05 196.232 207.821C196.461 207.593 196.59 207.282 196.59 206.959C196.59 206.635 196.461 206.325 196.232 206.096C196.003 205.868 195.692 205.739 195.368 205.739H88.6101C88.2862 205.739 87.9755 205.868 87.7464 206.096C87.5174 206.325 87.3887 206.635 87.3887 206.959C87.3887 207.282 87.5174 207.593 87.7464 207.821C87.9755 208.05 88.2862 208.178 88.6101 208.178Z" fill="white"/>
  <path opacity="0.3" d="M132.415 183.995C132.263 183.993 132.113 183.958 131.975 183.893C131.838 183.827 131.717 183.732 131.62 183.614C131.523 183.497 131.454 183.359 131.416 183.212C131.378 183.065 131.374 182.911 131.402 182.761L131.832 180.551C131.893 180.249 132.056 179.978 132.294 179.782C132.531 179.586 132.829 179.479 133.137 179.477H151.181C151.334 179.476 151.486 179.51 151.624 179.576C151.762 179.642 151.883 179.738 151.979 179.858C152.078 179.974 152.149 180.111 152.186 180.259C152.223 180.407 152.226 180.561 152.194 180.71L151.764 182.921C151.706 183.222 151.545 183.494 151.308 183.69C151.071 183.886 150.774 183.994 150.466 183.995H132.415Z" fill="white"/>
  <path d="M150.466 183.647H132.422C132.319 183.649 132.218 183.628 132.124 183.586C132.031 183.543 131.948 183.481 131.881 183.403C131.815 183.324 131.767 183.232 131.74 183.133C131.714 183.034 131.71 182.931 131.728 182.83L132.165 180.612C132.208 180.39 132.326 180.189 132.501 180.045C132.676 179.9 132.896 179.822 133.123 179.822H151.167C151.27 179.821 151.372 179.842 151.465 179.884C151.559 179.926 151.642 179.989 151.708 180.067C151.774 180.145 151.822 180.237 151.849 180.336C151.875 180.435 151.88 180.539 151.861 180.64L151.431 182.857C151.387 183.081 151.267 183.282 151.091 183.426C150.915 183.57 150.694 183.648 150.466 183.647Z" fill="white"/>
  <path d="M135.474 182.251C135.828 181.746 135.789 181.109 135.387 180.828C134.985 180.548 134.372 180.729 134.018 181.234C133.665 181.739 133.704 182.376 134.106 182.657C134.508 182.938 135.121 182.756 135.474 182.251Z" fill="#263238"/>
  <path d="M138.516 181.735C138.466 182.012 138.326 182.264 138.118 182.454C137.909 182.643 137.644 182.758 137.363 182.781C137.239 182.777 137.117 182.745 137.008 182.686C136.898 182.627 136.803 182.544 136.731 182.443C136.659 182.341 136.612 182.224 136.593 182.102C136.574 181.979 136.583 181.853 136.621 181.735C136.672 181.458 136.812 181.206 137.02 181.017C137.228 180.828 137.493 180.713 137.773 180.688C137.898 180.691 138.02 180.723 138.131 180.781C138.241 180.839 138.337 180.923 138.409 181.024C138.481 181.126 138.528 181.244 138.547 181.367C138.565 181.49 138.555 181.616 138.516 181.735Z" fill="#263238"/>
  <path d="M141.34 181.735C141.29 182.011 141.149 182.263 140.941 182.452C140.733 182.641 140.469 182.757 140.188 182.781C140.063 182.778 139.941 182.747 139.83 182.688C139.72 182.63 139.624 182.547 139.551 182.445C139.479 182.344 139.431 182.227 139.411 182.103C139.391 181.98 139.401 181.854 139.439 181.735C139.491 181.458 139.632 181.206 139.842 181.017C140.051 180.828 140.317 180.712 140.598 180.688C140.722 180.692 140.844 180.725 140.954 180.784C141.063 180.842 141.158 180.926 141.23 181.027C141.302 181.129 141.349 181.245 141.368 181.368C141.387 181.491 141.378 181.616 141.34 181.735Z" fill="#263238"/>
  <path d="M143.942 182.246C144.296 181.741 144.257 181.104 143.855 180.823C143.453 180.543 142.84 180.724 142.487 181.229C142.133 181.734 142.172 182.371 142.574 182.652C142.976 182.933 143.589 182.751 143.942 182.246Z" fill="#263238"/>
  <path d="M146.982 181.735C146.93 182.012 146.789 182.264 146.579 182.453C146.37 182.642 146.105 182.757 145.823 182.781C145.699 182.777 145.577 182.745 145.468 182.686C145.358 182.627 145.263 182.544 145.191 182.443C145.119 182.341 145.072 182.224 145.053 182.102C145.034 181.979 145.043 181.853 145.081 181.735C145.133 181.459 145.273 181.207 145.481 181.019C145.689 180.83 145.953 180.714 146.233 180.688C146.358 180.691 146.481 180.723 146.591 180.781C146.702 180.84 146.797 180.923 146.87 181.024C146.942 181.126 146.991 181.243 147.01 181.366C147.03 181.49 147.02 181.616 146.982 181.735Z" fill="#263238"/>
  <path d="M149.581 182.257C149.934 181.752 149.895 181.115 149.493 180.834C149.091 180.553 148.478 180.735 148.125 181.24C147.771 181.745 147.81 182.382 148.212 182.663C148.614 182.943 149.227 182.762 149.581 182.257Z" fill="#263238"/>
  <path opacity="0.3" d="M152.986 183.994C152.869 183.996 152.753 183.971 152.648 183.922C152.542 183.873 152.449 183.8 152.375 183.71C152.296 183.615 152.24 183.503 152.213 183.383C152.185 183.262 152.186 183.137 152.215 183.017L152.763 180.245C152.812 180.018 152.935 179.814 153.114 179.666C153.293 179.517 153.517 179.434 153.749 179.428H156.525C156.642 179.426 156.757 179.451 156.863 179.5C156.968 179.55 157.062 179.622 157.136 179.712C157.215 179.807 157.27 179.919 157.298 180.039C157.326 180.16 157.325 180.285 157.295 180.405L156.747 183.177C156.699 183.404 156.575 183.608 156.396 183.756C156.218 183.905 155.994 183.989 155.762 183.994H152.986Z" fill="white"/>
  <path d="M152.986 183.648H155.762C155.911 183.64 156.053 183.585 156.168 183.49C156.283 183.395 156.364 183.266 156.4 183.121L156.948 180.349C156.966 180.285 156.968 180.216 156.954 180.151C156.94 180.085 156.911 180.023 156.869 179.971C156.828 179.918 156.774 179.876 156.713 179.848C156.652 179.82 156.585 179.807 156.518 179.809H153.742C153.59 179.816 153.444 179.872 153.328 179.97C153.211 180.068 153.13 180.201 153.097 180.349L152.548 183.121C152.533 183.186 152.533 183.253 152.549 183.317C152.564 183.382 152.594 183.442 152.636 183.493C152.678 183.544 152.732 183.584 152.793 183.611C152.853 183.638 152.919 183.651 152.986 183.648Z" fill="#263238"/>
  <path d="M154.394 182.948C154.373 182.949 154.352 182.945 154.333 182.937C154.314 182.928 154.297 182.916 154.283 182.9C154.258 182.86 154.25 182.812 154.259 182.765C154.268 182.719 154.294 182.678 154.332 182.65L155.435 181.736L154.7 180.821C154.673 180.781 154.663 180.733 154.672 180.686C154.681 180.639 154.709 180.598 154.748 180.571C154.781 180.541 154.825 180.524 154.87 180.524C154.915 180.524 154.958 180.541 154.991 180.571L155.824 181.611C155.85 181.65 155.86 181.699 155.851 181.746C155.842 181.792 155.815 181.833 155.775 181.86L154.533 182.9C154.494 182.932 154.445 182.949 154.394 182.948Z" fill="#02AFCD"/>
  <path d="M155.643 181.909H153.77C153.748 181.909 153.727 181.905 153.707 181.896C153.688 181.887 153.67 181.873 153.657 181.857C153.643 181.84 153.634 181.82 153.63 181.799C153.625 181.778 153.625 181.756 153.631 181.735C153.642 181.687 153.668 181.644 153.705 181.613C153.743 181.582 153.79 181.563 153.839 181.562H155.706C155.728 181.561 155.749 181.565 155.769 181.574C155.788 181.583 155.805 181.597 155.819 181.614C155.832 181.63 155.842 181.65 155.846 181.671C155.851 181.692 155.85 181.714 155.845 181.735C155.834 181.782 155.809 181.824 155.773 181.855C155.737 181.887 155.691 181.905 155.643 181.909Z" fill="#02AFCD"/>
  <path d="M94.8211 139.604H109.784C111.363 139.604 112.643 138.326 112.643 136.75V121.809C112.643 120.233 111.363 118.954 109.784 118.954H94.8211C93.242 118.954 91.9618 120.233 91.9618 121.809V136.75C91.9618 138.326 93.242 139.604 94.8211 139.604Z" fill="#02AFCD"/>
  <path d="M100.686 138.828L102.303 145.772L103.92 138.828H100.686Z" fill="#02AFCD"/>
  <path opacity="0.3" d="M102.122 135.467C100.362 134.42 98.8927 132.949 97.8478 131.189C96.8029 129.429 96.2158 127.436 96.1399 125.392V125.17L102.296 122.551L108.444 125.17V125.392C108.373 127.437 107.789 129.431 106.745 131.191C105.701 132.952 104.23 134.422 102.469 135.467L102.289 135.578L102.122 135.467Z" fill="white"/>
  <path opacity="0.6" d="M102.302 122.932L108.111 125.399C108.048 127.385 107.482 129.324 106.466 131.033C105.451 132.743 104.018 134.168 102.302 135.176V122.932Z" fill="white"/>
  <path opacity="0.4" d="M102.303 122.932L96.4868 125.399C96.551 127.386 97.1179 129.325 98.1348 131.034C99.1517 132.744 100.585 134.169 102.303 135.176V122.932Z" fill="white"/>
  <path d="M96.7161 201.215L101.574 201.942C102.677 201.592 103.811 201.348 104.961 201.215C106.208 201.112 107.461 201.112 108.708 201.215C108.708 201.215 110.908 202.039 110.908 204.139C110.908 204.139 105.78 205.525 103.816 204.686L96.7161 204.139V201.215Z" fill="#E4897B"/>
  <path opacity="0.2" d="M96.7161 201.215L101.574 201.942C102.677 201.592 103.811 201.348 104.961 201.215C106.208 201.112 107.461 201.112 108.708 201.215C108.708 201.215 110.908 202.039 110.908 204.139C110.908 204.139 105.78 205.525 103.816 204.686L96.7161 204.139V201.215Z" fill="black"/>
  <path d="M108.75 168.458C108.826 169.455 109.021 170.439 109.333 171.389C109.078 171.546 108.785 171.628 108.486 171.628C108.187 171.628 107.894 171.546 107.64 171.389L108.75 168.458Z" fill="#AA473D"/>
  <path d="M110.644 167.073C110.457 167.523 110.124 167.814 109.895 167.717C109.666 167.62 109.645 167.191 109.826 166.74C110.006 166.29 110.346 166.006 110.575 166.096C110.804 166.186 110.825 166.629 110.644 167.073Z" fill="#263238"/>
  <path d="M111.783 165.52C111.742 165.532 111.699 165.532 111.658 165.52C111.289 165.304 110.876 165.173 110.45 165.137C110.023 165.101 109.594 165.161 109.194 165.312C109.134 165.343 109.065 165.348 109.001 165.327C108.937 165.306 108.884 165.261 108.854 165.201C108.824 165.142 108.818 165.072 108.839 165.009C108.86 164.945 108.905 164.892 108.965 164.862C109.441 164.668 109.955 164.588 110.467 164.628C110.979 164.668 111.475 164.826 111.915 165.091C111.943 165.108 111.968 165.13 111.988 165.157C112.007 165.184 112.021 165.214 112.029 165.246C112.037 165.279 112.038 165.312 112.033 165.345C112.027 165.378 112.016 165.409 111.998 165.437C111.972 165.468 111.939 165.492 111.901 165.507C111.864 165.521 111.823 165.526 111.783 165.52Z" fill="#263238"/>
  <path d="M107.397 172.228C107.237 172.422 107.077 172.609 106.904 172.782C105.932 173.791 104.676 174.48 103.302 174.757C102.954 174.828 102.602 174.874 102.247 174.896C101.314 174.954 100.379 174.804 99.5113 174.456C98.6434 174.109 97.8639 173.572 97.2296 172.886C96.6571 172.266 96.2179 171.535 95.9387 170.738C94.6965 167.412 95.9387 164.793 97.7362 160.184C98.1358 159.104 98.8051 158.144 99.6806 157.394C100.556 156.644 101.609 156.13 102.739 155.901C103.869 155.671 105.039 155.733 106.139 156.081C107.238 156.43 108.23 157.052 109.021 157.891C112.616 161.674 110.2 168.625 107.397 172.228Z" fill="#E4897B"/>
  <path opacity="0.2" d="M103.302 174.758C102.954 174.828 102.602 174.874 102.247 174.896C101.314 174.955 100.379 174.804 99.5115 174.457C98.6435 174.109 97.8641 173.573 97.2297 172.887C97.6756 172.439 98.1771 172.05 98.7218 171.729C100.509 172.278 102.099 173.329 103.302 174.758Z" fill="black"/>
  <path d="M88.3394 174.93C88.7974 178.173 95.904 179.386 95.904 179.386C95.904 179.386 99.3739 180.98 99.5544 179.594C98.1039 177.917 97.9443 176.455 98.444 175.173C98.7755 174.391 99.2618 173.684 99.8736 173.094C100.2 172.756 100.545 172.436 100.908 172.138L98.7355 163.254C97.174 166.649 91.8579 174.445 88.3394 174.93Z" fill="#E4897B"/>
  <path d="M108.618 155.403C108.618 155.403 110.138 155.216 111.304 158.043C112.282 160.42 109.971 160.94 110.075 163.469C110.18 165.998 107.855 167.405 107.855 167.405C107.855 167.405 105.911 169.082 104.253 168.174C104.253 168.174 102.088 169.234 101.518 171.085C100.949 172.935 97.5974 169.595 94.5785 170.101C94.5785 170.101 92.5381 168.195 92.6908 163.566C92.8062 161.556 93.3007 159.586 94.1482 157.759C94.5484 156.792 95.1374 155.914 95.8809 155.176C96.6244 154.439 97.5075 153.856 98.4787 153.463C101.928 151.897 107.973 153.061 108.618 155.403Z" fill="#263238"/>
  <path d="M103.836 166.781C103.731 167.242 103.731 167.721 103.834 168.182C103.937 168.644 104.142 169.076 104.433 169.449C105.169 170.253 106.356 169.234 106.834 167.827C107.251 166.573 107.397 164.612 106.314 164.363C105.231 164.113 104.065 165.43 103.836 166.781Z" fill="#E4897B"/>
  <path d="M104.6 177.612C103.605 180.384 102.717 183.04 101.935 185.581C101.435 187.161 100.977 188.706 100.547 190.196C100.195 191.448 99.8667 192.663 99.5614 193.841C99.4156 194.409 99.2699 194.971 99.1311 195.525C98.3677 198.581 97.7431 201.367 97.2642 203.84C96.4554 207.702 95.8554 211.604 95.4668 215.531L68.262 212.107C69.2058 207.437 69.9414 203.355 70.9686 198.941C71.5376 196.53 72.1831 194.021 73.0506 191.27C73.6335 189.295 74.3136 187.196 75.1326 184.916C77.8669 177.085 80.3862 174.916 80.3862 174.916C90.7892 173.253 104.6 177.612 104.6 177.612Z" fill="#263238"/>
  <path d="M113.656 201.72L118.514 202.448C119.618 202.098 120.752 201.854 121.901 201.72C123.151 201.618 124.406 201.618 125.656 201.72C125.656 201.72 127.849 202.545 127.849 204.645C127.849 204.645 122.727 206.031 120.756 205.192L113.663 204.645L113.656 201.72Z" fill="#E4897B"/>
  <path d="M83.5854 291.298L88.6586 291.388L91.1848 279.712L86.1186 279.622L83.5854 291.298Z" fill="#EBB376"/>
  <path opacity="0.2" d="M86.1186 279.622L91.1848 279.719L89.7413 286.392L84.7236 286.06L86.1186 279.622Z" fill="black"/>
  <path d="M99.8875 262.708C97.9544 270.139 95.4842 277.42 92.4964 284.494L83.2454 283.801C83.2454 283.801 87.0415 268.993 88.4157 255.425C89.2693 247.054 89.1929 240.124 86.7362 239.591C85.1955 239.251 81.5798 239.258 76.9438 239.369V222.114C89.9425 224.782 102.879 229.806 103.864 233.839C105.238 239.514 102.837 251.53 99.8875 262.708Z" fill="#02AFCD"/>
  <path d="M82.26 284.216L93.2807 284.951L94.9047 280.19L82.7597 279.629L82.26 284.216Z" fill="#02AFCD"/>
  <path opacity="0.2" d="M82.26 284.216L93.2807 284.951L94.9047 280.19L82.7597 279.629L82.26 284.216Z" fill="white"/>
  <path d="M89.422 291.139H89.5261C89.5261 291.139 89.6441 291.174 89.6579 291.188C89.6579 291.188 89.6718 291.202 89.6579 291.188H89.6996C90.1282 291.323 90.5703 291.411 91.0182 291.451C91.3519 291.513 91.6966 291.446 91.9828 291.264C92.0736 291.185 92.1396 291.081 92.1729 290.966C92.2061 290.85 92.2052 290.728 92.1702 290.613C92.1591 290.537 92.13 290.466 92.0853 290.404C92.0406 290.343 91.9816 290.293 91.9134 290.259C91.4342 290.183 90.9432 290.276 90.5254 290.522C90.824 290.208 91.0011 289.798 91.0251 289.365C91.0047 289.263 90.9518 289.17 90.8743 289.1C90.7968 289.03 90.6988 288.987 90.5948 288.977C90.3975 288.948 90.1965 288.995 90.0327 289.109C89.4983 289.566 89.415 291.001 89.415 291.084L89.422 291.139ZM89.9702 290.848L89.8661 290.904C89.8333 290.356 89.9821 289.813 90.2895 289.358C90.3229 289.325 90.3631 289.299 90.4074 289.282C90.4517 289.265 90.499 289.258 90.5463 289.261H90.6087C90.7753 289.261 90.81 289.372 90.8169 289.441C90.7098 289.995 90.4099 290.494 89.9702 290.848ZM90.0396 291.098C90.6504 290.758 91.5179 290.405 91.844 290.536C91.8765 290.553 91.9042 290.578 91.9248 290.608C91.9454 290.638 91.9582 290.673 91.962 290.71C91.9825 290.782 91.983 290.859 91.9634 290.933C91.9439 291.006 91.9051 291.072 91.851 291.125C91.6011 291.347 90.9349 291.333 90.0396 291.098Z" fill="#02AFCD"/>
  <path d="M89.304 290.53L83.6201 290.177C83.5254 290.167 83.4307 290.195 83.3566 290.255C83.2825 290.314 83.2351 290.401 83.2246 290.495L82.6694 294.965C82.6541 295.2 82.7322 295.431 82.8869 295.609C83.0415 295.787 83.2601 295.897 83.4952 295.914C85.4801 296.004 86.4308 295.949 88.9292 296.088C91.1469 296.315 93.378 296.385 95.6055 296.296C97.6875 296.095 97.4585 294.009 96.5494 293.912C94.3771 293.662 91.5109 292.075 90.1507 290.87C89.9148 290.664 89.6165 290.545 89.304 290.53Z" fill="#263238"/>
  <path opacity="0.2" d="M99.8875 262.708C96.4175 260.525 92.3853 257.954 88.4157 255.411C89.2693 247.04 89.1929 240.11 86.7362 239.577C85.1955 239.237 81.5798 239.244 76.9438 239.355V222.114C89.9425 224.782 102.879 229.806 103.864 233.839C105.238 239.514 102.837 251.53 99.8875 262.708Z" fill="black"/>
  <path d="M113.24 291.298L118.313 291.388L120.84 279.712L115.773 279.622L113.24 291.298Z" fill="#EBB376"/>
  <path opacity="0.2" d="M115.773 279.622L120.84 279.719L119.396 286.392L114.378 286.06L115.773 279.622Z" fill="black"/>
  <path d="M94.8213 220.423L95.6402 213.881L69.1503 209.522C69.1503 209.522 60.4822 226.666 64.7226 235.093C68.6159 242.798 110.02 238.204 116.391 239.597C122.762 240.99 112.921 283.814 112.921 283.814L122.179 284.507C122.179 284.507 136.475 245.979 133.54 233.859C132.235 228.558 110.409 221.552 94.8213 220.423Z" fill="#02AFCD"/>
  <path d="M111.915 284.216L122.935 284.951L124.559 280.19L112.414 279.629L111.915 284.216Z" fill="#02AFCD"/>
  <path opacity="0.2" d="M111.915 284.216L122.935 284.951L124.559 280.19L112.414 279.629L111.915 284.216Z" fill="white"/>
  <path d="M119.077 291.139H119.181C119.181 291.139 119.299 291.174 119.312 291.188C119.312 291.188 119.326 291.202 119.312 291.188H119.354C119.783 291.323 120.225 291.411 120.673 291.451C121.006 291.513 121.351 291.446 121.637 291.264C121.728 291.185 121.794 291.081 121.827 290.966C121.861 290.85 121.86 290.728 121.825 290.613C121.814 290.537 121.785 290.466 121.74 290.404C121.695 290.343 121.636 290.293 121.568 290.259C121.089 290.183 120.598 290.276 120.18 290.522C120.479 290.208 120.656 289.798 120.68 289.365C120.659 289.263 120.606 289.17 120.529 289.1C120.451 289.03 120.353 288.987 120.249 288.977C120.052 288.948 119.851 288.995 119.687 289.109C119.153 289.566 119.07 291.001 119.07 291.084L119.077 291.139ZM119.625 290.848L119.521 290.904C119.488 290.356 119.637 289.813 119.944 289.358C119.977 289.325 120.018 289.299 120.062 289.282C120.106 289.265 120.154 289.258 120.201 289.261H120.263C120.43 289.261 120.465 289.372 120.471 289.441C120.365 289.997 120.062 290.497 119.618 290.848H119.625ZM119.694 291.098C120.305 290.758 121.172 290.405 121.499 290.536C121.531 290.553 121.559 290.578 121.579 290.608C121.6 290.638 121.613 290.673 121.617 290.71C121.637 290.782 121.638 290.859 121.618 290.933C121.598 291.006 121.56 291.072 121.506 291.125C121.256 291.347 120.589 291.333 119.694 291.098Z" fill="#02AFCD"/>
  <path d="M118.959 290.53L113.275 290.177C113.18 290.167 113.085 290.195 113.011 290.255C112.937 290.314 112.89 290.401 112.879 290.495L112.324 294.965C112.309 295.2 112.387 295.431 112.542 295.609C112.696 295.787 112.915 295.897 113.15 295.914C115.135 296.004 116.086 295.949 118.584 296.088C120.802 296.315 123.033 296.385 125.26 296.296C127.377 296.095 127.12 294.009 126.204 293.912C124.032 293.662 121.166 292.075 119.805 290.87C119.57 290.664 119.271 290.545 118.959 290.53Z" fill="#263238"/>
  <path d="M68.1787 212.502L80.4486 214.11L80.8512 212.301L68.5535 210.514L68.1787 212.502Z" fill="black"/>
  <path d="M81.8714 212.315L81.7188 214.11L93.0656 215.669V213.888L81.8714 212.315Z" fill="black"/>
  <path d="M93.7666 213.992V215.669L95.4183 215.884V214.262L93.7666 213.992Z" fill="black"/>
  <path opacity="0.2" d="M101.935 185.582C101.435 187.162 100.977 188.707 100.547 190.197C100.195 191.449 99.8669 192.664 99.5615 193.842C98.7079 189.199 100.256 183.655 100.256 183.655L101.935 185.582Z" fill="black"/>
  <path d="M104.6 177.578C106.814 178.029 107.543 195.207 107.64 197.036C107.737 198.866 118.508 202.421 118.508 202.421V204.922C118.508 204.922 106.96 204.118 104.26 203.017C101.56 201.915 100.88 186.004 100.88 186.004L104.6 177.578Z" fill="#E4897B"/>
  <path d="M104.6 177.578C106.633 178.209 107.64 189.539 107.64 189.539L101.352 192.13L99.7905 185.831C99.7905 185.831 99.3464 184.549 100.221 181.847C101.491 177.904 104.6 177.578 104.6 177.578Z" fill="#263238"/>
  <path d="M57.6993 234.067H107.82C109.276 234.067 110.672 234.645 111.702 235.673C112.731 236.701 113.309 238.095 113.309 239.549V242.48H52.2097V239.549C52.2097 238.095 52.7881 236.701 53.8176 235.673C54.847 234.645 56.2433 234.067 57.6993 234.067Z" fill="#02AFCD"/>
  <path d="M74.0015 213.23H107.654C108.059 213.23 108.46 213.322 108.825 213.498C109.19 213.674 109.511 213.93 109.763 214.247C110.015 214.564 110.192 214.934 110.281 215.329C110.37 215.724 110.369 216.134 110.277 216.529L104.26 242.48H102.872L107.994 219.737C108.083 219.344 108.083 218.936 107.993 218.543C107.903 218.15 107.725 217.782 107.473 217.467C107.221 217.152 106.901 216.898 106.537 216.724C106.173 216.549 105.774 216.459 105.37 216.46H74.6885L74.0015 213.23Z" fill="#263238"/>
  <path d="M86.7431 243.408V265.34C86.7431 265.678 86.6086 266.003 86.3691 266.242C86.1296 266.481 85.8048 266.615 85.4662 266.615H80.0599C79.7212 266.615 79.3964 266.481 79.157 266.242C78.9175 266.003 78.783 265.678 78.783 265.34V243.408H86.7431Z" fill="#263238"/>
  <path d="M57.0123 294.035C57.0123 293.575 56.8756 293.125 56.6193 292.742C56.3631 292.359 55.9989 292.061 55.5728 291.884C55.1467 291.708 54.6779 291.662 54.2256 291.752C53.7732 291.842 53.3577 292.063 53.0316 292.389C52.7055 292.715 52.4834 293.129 52.3934 293.581C52.3035 294.033 52.3496 294.501 52.5261 294.926C52.7026 295.352 53.0015 295.715 53.385 295.971C53.7684 296.227 54.2193 296.364 54.6805 296.364C55.2989 296.364 55.892 296.118 56.3293 295.682C56.7666 295.245 57.0123 294.653 57.0123 294.035Z" fill="#263238"/>
  <path opacity="0.2" d="M55.8462 294.036C55.8462 293.806 55.7779 293.581 55.6498 293.389C55.5216 293.198 55.3395 293.049 55.1265 292.961C54.9135 292.873 54.679 292.85 54.4529 292.894C54.2267 292.939 54.019 293.05 53.8559 293.213C53.6928 293.376 53.5818 293.583 53.5368 293.809C53.4918 294.035 53.5149 294.269 53.6032 294.482C53.6914 294.694 53.8408 294.876 54.0326 295.004C54.2243 295.132 54.4497 295.2 54.6803 295.2C54.989 295.199 55.2845 295.075 55.5027 294.857C55.721 294.639 55.8444 294.344 55.8462 294.036Z" fill="white"/>
  <path d="M113.191 294.036C113.193 293.573 113.057 293.121 112.8 292.736C112.544 292.35 112.179 292.05 111.751 291.872C111.323 291.695 110.852 291.648 110.398 291.738C109.944 291.828 109.526 292.05 109.199 292.377C108.871 292.704 108.649 293.121 108.559 293.575C108.468 294.028 108.515 294.498 108.693 294.925C108.871 295.352 109.172 295.717 109.558 295.973C109.943 296.229 110.396 296.365 110.86 296.364C111.478 296.362 112.07 296.116 112.506 295.68C112.943 295.244 113.19 294.652 113.191 294.036Z" fill="#263238"/>
  <path opacity="0.2" d="M112.026 294.036C112.026 293.806 111.957 293.581 111.829 293.389C111.701 293.198 111.519 293.049 111.306 292.961C111.093 292.873 110.858 292.85 110.632 292.894C110.406 292.939 110.198 293.05 110.035 293.213C109.872 293.376 109.761 293.583 109.716 293.809C109.671 294.035 109.694 294.269 109.783 294.482C109.871 294.694 110.02 294.876 110.212 295.004C110.404 295.132 110.629 295.2 110.86 295.2C111.168 295.199 111.464 295.075 111.682 294.857C111.9 294.639 112.024 294.344 112.026 294.036Z" fill="white"/>
  <path d="M84.696 283.807V258.736H80.8374V283.807C65.8053 284.196 56.6653 289.441 54.8401 290.592C54.7324 290.655 54.6439 290.747 54.584 290.857C54.5241 290.966 54.4951 291.09 54.5 291.215V293.294C54.4991 293.391 54.5175 293.487 54.554 293.577C54.5906 293.667 54.6446 293.749 54.713 293.818C54.7814 293.887 54.8629 293.942 54.9526 293.979C55.0423 294.016 55.1385 294.036 55.2357 294.036C55.3656 294.034 55.4929 293.999 55.605 293.934C55.717 293.868 55.8098 293.774 55.8742 293.661L56.7694 292.095C64.5575 289.616 72.6644 288.276 80.8374 288.118V292.275C80.8374 292.401 80.8622 292.525 80.9104 292.64C80.9586 292.756 81.0293 292.861 81.1183 292.949C81.2073 293.037 81.3129 293.107 81.4291 293.155C81.5452 293.202 81.6696 293.226 81.7951 293.225H83.7452C83.9974 293.225 84.2392 293.125 84.4175 292.947C84.5958 292.769 84.696 292.527 84.696 292.275V288.118C92.8713 288.275 100.98 289.615 108.771 292.095L109.659 293.661C109.724 293.775 109.818 293.87 109.931 293.935C110.045 294.001 110.174 294.036 110.305 294.036C110.5 294.034 110.687 293.955 110.825 293.816C110.963 293.677 111.04 293.49 111.04 293.294V291.215C111.045 291.09 111.016 290.966 110.956 290.857C110.896 290.747 110.808 290.655 110.7 290.592C108.868 289.441 99.735 284.196 84.696 283.807Z" fill="#263238"/>
  <path d="M84.0365 291.7H81.5034V296.364H84.0365V291.7Z" fill="#263238"/>
  <path opacity="0.2" d="M86.7433 243.388H78.804V252.5H86.7433V243.388Z" fill="black"/>
  <path d="M115.003 241.128V243.748C115.003 243.924 114.968 244.098 114.9 244.261C114.832 244.424 114.733 244.572 114.608 244.696C114.483 244.82 114.334 244.919 114.171 244.985C114.008 245.052 113.833 245.086 113.656 245.085H51.8905C51.7143 245.086 51.5397 245.052 51.3768 244.985C51.2139 244.918 51.0659 244.82 50.9413 244.696C50.8167 244.571 50.7181 244.423 50.6511 244.261C50.5841 244.098 50.5501 243.924 50.551 243.748V241.128C50.551 240.774 50.6922 240.434 50.9433 240.183C51.1945 239.932 51.5352 239.791 51.8905 239.791H113.656C114.012 239.791 114.354 239.932 114.606 240.182C114.858 240.433 115.001 240.773 115.003 241.128Z" fill="#263238"/>
  <path d="M86.5836 242.48H50.5442C50.5442 242.48 39.114 223.465 39.114 192.046V180.509C39.1159 178.231 40.0233 176.047 41.637 174.437C43.2506 172.827 45.4385 171.923 47.7196 171.923H62.9876C65.222 171.922 67.369 172.789 68.974 174.341C70.579 175.893 71.5159 178.008 71.5863 180.238C72.0235 196.454 74.4108 231.51 86.5836 242.48Z" fill="#263238"/>
  <path opacity="0.2" d="M78.672 242.48H52.1682C51.6671 242.482 51.1751 242.346 50.7464 242.087C50.3178 241.827 49.9693 241.455 49.7392 241.011C47.1297 235.994 39.114 218.372 39.114 192.046V180.509C39.114 178.232 40.0199 176.048 41.6325 174.438C43.2451 172.827 45.4322 171.923 47.7127 171.923H60.8987C63.1331 171.922 65.2801 172.789 66.8851 174.341C68.4901 175.893 69.427 178.008 69.4973 180.238C69.8929 194.7 71.8361 224.227 80.9275 238.176C81.2022 238.59 81.3607 239.07 81.3864 239.565C81.4121 240.061 81.3041 240.554 81.0736 240.994C80.8432 241.433 80.4988 241.803 80.0763 242.065C79.6539 242.326 79.1689 242.469 78.672 242.48Z" fill="white"/>
  <path opacity="0.2" d="M115.003 241.128V243.748C115.003 244.102 114.862 244.443 114.611 244.693C114.359 244.944 114.019 245.085 113.663 245.085H85.9798C85.7423 245.086 85.5069 245.04 85.2872 244.95C85.0674 244.86 84.8677 244.727 84.6994 244.56C84.5311 244.393 84.3976 244.194 84.3065 243.975C84.2154 243.756 84.1685 243.521 84.1685 243.284V241.6C84.1685 241.12 84.3593 240.66 84.699 240.321C85.0387 239.982 85.4994 239.791 85.9798 239.791H113.657C114.012 239.791 114.354 239.932 114.606 240.182C114.858 240.433 115.001 240.773 115.003 241.128Z" fill="black"/>
  <path d="M50.5303 170.905L52.8205 167.911C52.8205 167.911 62.5989 168.355 62.8349 171.923C63.0708 175.492 50.5303 170.905 50.5303 170.905Z" fill="#02AFCD"/>
  <path d="M44.1039 179.864C44.1039 179.864 44.7007 195.733 44.1039 236.687C44.1039 238.073 38.9266 238.017 38.5519 236.687C37.3096 232.023 38.5519 194.784 38.5519 194.784" fill="#02AFCD"/>
  <path d="M38.6699 236.895L43.9374 237.075V239.701L38.6699 239.431V236.895Z" fill="#02AFCD"/>
  <path opacity="0.2" d="M44.1039 179.864C44.1039 179.864 44.7007 195.733 44.1039 236.687C44.1039 238.073 38.9266 238.017 38.5519 236.687C37.3096 232.023 38.5519 194.784 38.5519 194.784" fill="white"/>
  <path d="M36.0674 228.316C35.7829 220.763 34.7488 186.046 39.0933 174.543C39.0933 174.543 43.1809 169.359 57.0123 169.359C70.8438 169.359 75.0078 179.165 75.0078 179.165C75.0078 179.165 75.6046 195.027 75.0078 235.987C75.0078 237.373 69.8305 237.325 69.4558 235.987C68.2135 231.331 69.4558 194.084 69.4558 194.084L66.6798 227.97C66.6696 228.083 66.6364 228.194 66.5821 228.294C66.5277 228.394 66.4535 228.483 66.3638 228.553C66.2742 228.624 66.171 228.676 66.0607 228.705C65.9503 228.735 65.8351 228.741 65.722 228.725C61.3568 228.032 42.126 225.336 37.039 228.801C36.9469 228.86 36.8408 228.895 36.7314 228.901C36.622 228.906 36.5129 228.884 36.4149 228.835C36.3169 228.786 36.2333 228.712 36.1724 228.621C36.1115 228.53 36.0753 228.425 36.0674 228.316Z" fill="#02AFCD"/>
  <path d="M69.5737 236.195L74.8412 236.375V239.002L69.5737 238.732V236.195Z" fill="#02AFCD"/>
  <path d="M66.0065 228.76L65.5901 231.531C65.5901 231.531 44.1247 226.861 37.1708 231.836L36.7544 228.766C36.7544 228.766 42.126 223.819 66.0065 228.76Z" fill="#02AFCD"/>
  <path opacity="0.4" d="M36.0674 228.316C35.7829 220.763 34.7488 186.046 39.0933 174.543C39.0933 174.543 43.1809 169.359 57.0123 169.359C70.8438 169.359 75.0078 179.165 75.0078 179.165C75.0078 179.165 75.6046 195.027 75.0078 235.987C75.0078 237.373 69.8305 237.325 69.4558 235.987C68.2135 231.331 69.4558 194.084 69.4558 194.084L66.6798 227.97C66.6696 228.083 66.6364 228.194 66.5821 228.294C66.5277 228.394 66.4535 228.483 66.3638 228.553C66.2742 228.624 66.171 228.676 66.0607 228.705C65.9503 228.735 65.8351 228.741 65.722 228.725C61.3568 228.032 42.126 225.336 37.039 228.801C36.9469 228.86 36.8408 228.895 36.7314 228.901C36.622 228.906 36.5129 228.884 36.4149 228.835C36.3169 228.786 36.2333 228.712 36.1724 228.621C36.1115 228.53 36.0753 228.425 36.0674 228.316Z" fill="white"/>
  <path opacity="0.2" d="M69.4766 194.084C69.4766 194.084 69.782 183.33 75.0286 179.165C75.0078 179.165 68.4703 181.972 69.4766 194.084Z" fill="black"/>
  <path opacity="0.2" d="M53.7366 170.6C53.7366 170.6 47.4212 174.064 46.4149 221.275C46.4149 221.275 43.6667 173.649 53.7366 170.6Z" fill="black"/>
  </svg>
`;