import { Button } from '@material/mwc-button';
import { css, unsafeCSS } from "@dreamworld/pwa-helpers/lit.js";

// Theme
import typographyLiterals from '../../theme/typography-literals.js';

export class smpvButton extends Button {
  static get styles() {
    return [
      Button.styles,
      css`
        :host {
          ${unsafeCSS(typographyLiterals.button)};
          overflow: hidden;
          white-space: nowrap;
        }

        .leading-icon {
          display: flex;
        }

        .mdc-button {
          height: var(--hisab-button-height, 36px);
          border-radius: calc(var(--hisab-button-height, 36px) / 2);
          border-width: var(--hisab-button-border-width, 1px);
          justify-content: var(--hisab-button-justify-content, center);
          padding: var(--hisab-button-padding, 0 18px);
        }

        :host([type='primary'][disabled]) .mdc-button {
          background-color: var(--hisab-theme-divider, rgba(0, 0, 0, 0.12));
        }

        :host([type='secondary']) {
          --mdc-button-outline-color: var(--mdc-theme-primary);
        }

        :host([type='danger']) {
          --mdc-button-outline-color: var(--mdc-theme-error);
          --mdc-theme-primary: var(--mdc-theme-error);
        }

        :host([disabled]) .mdc-button {
          border-color: var(--mdc-theme-text-disabled-on-surface);
          color: var(--mdc-theme-text-disabled-on-surface);
        }
      `,
    ];
  }

  static get properties() {
    return {
      /**
       * an enum, possible values
       * - regular(default) - Its Text button
       * - primary - Its fill button - unelevated - Creates a contained button that is flush with the surface.
       * - secondary - Its outline
       * - danger - Red outline
       */
      type: {
        type: String,
        reflect: true,
      },
    };
  }

  constructor() {
    super();
    this.type = 'regular';
  }

  set type(type) {
    let oldValue = this._type;

    if (type === oldValue) {
      return;
    }

    this._type = type;

    this.unelevated = this._type === "primary";
    this.outlined = this._type === "secondary" || this._type === "danger";

    this.requestUpdate("type", oldValue);
  }

  get type() {
    return this._type;
  }
}

customElements.define('smpv-button', smpvButton);
