import store from '../redux/store.js';
import * as app from '../redux/app';

import localize from '@dreamworld/pwa-helpers/localize.js';
import { LitElement, css } from '@dreamworld/pwa-helpers/lit.js';
import { connect } from '@dreamworld/pwa-helpers/connect-mixin.js';

export * from 'lit';

//Theme Imports
import sharedStyles from '../theme/shared-styles.js';
import typography from '../theme/typography.js';
import { flexLayout, alignment, factors, positioning, reverse } from '@dreamworld/flex-layout';

export * from 'lit';
import tailwindcss from '../tailwind.css';
/**
 * Base connected element mixin with `layout` attribute, shared styles & typography.
 */
export const baseElementMixin = (BaseElement) =>
  class extends connect(store)(localize()(BaseElement)) {
    static styles = [BaseElement.styles || css``, tailwindcss, sharedStyles, typography, flexLayout, alignment, factors, positioning, reverse, css``];

    static properties = {
      layout: { type: String, reflect: true },
    };

    stateChanged(state) {
      super.stateChanged && super.stateChanged(state);
      this.layout = app.selectors.layout(state);
    }
  };

/**
 * Base connected element with `layout` attribute, shared styles & typography.
 */
export class BaseElement extends baseElementMixin(LitElement) {}
